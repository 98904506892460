import { useEffect, useState } from "react"
import { App } from "../constants"
import { bond, transformBond } from "../shared/interfaces/bonds"
import { formatDuration, formatLotsOfTokens } from "../utils/format"
import { nowTimestampSeconds } from "../utils/time"
import { trace, debug } from "../utils/trace"

import { useAccount } from "wagmi"
import {
    useBondageClaimBond, usePrepareBondageClaimBond, useBondageBondClaimedEvent
} from "../web3/glue"

import { toast } from "react-toastify"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Modal from "react-bootstrap/Modal"

import { ErrorBoundary } from "../shared/tools/stry"
import FallbackGenericError from "./FallbackGenericError"


export default function MyBondClaimModal({ bond, show = false, onClose }: { bond: bond, show: boolean, onClose?: () => void }) {
    const account = useAccount()
    const [claimBondValidation, setClaimBondValidation] = useState(true)

    // Claim Bond

    const offBondClaimed = useBondageBondClaimedEvent({
        listener: async (data) => {
            const myClaimedBonds = [] as bond[]
            data.forEach((event) => {
                if (event.args.owner === account.address as `0x${string}` && event.args.bond) {
                    myClaimedBonds.push(transformBond(event.args.bond))
                }
            })
            if (myClaimedBonds.length === 0) {
                debug("onBondClaimed[NOT_MINE]", "MyBondClaimModal", data)
                return
            }
            for (const newBond of myClaimedBonds) {
                debug("newClaimedBond", "MyBondClaimModal", newBond)
            }
            toast(`Successfully claimed ${App.TokenTicker}`)
            closeModal()
        }
    })

    const { config: claimBondConfig } = usePrepareBondageClaimBond({
        args: [
            BigInt(bond.id), // bondId
        ],
        onSuccess: () => {
            setClaimBondValidation(true)
        },
        onError: (error) => {
            trace("claimBondValidationFailed", "MyBondClaimModal", error)
            setClaimBondValidation(false)
        }
    })

    const claimBond = useBondageClaimBond({
        ...claimBondConfig,
        onError: (error) => {
            trace("claimBondError", "MyBondClaimModal", error)
            toast.error(`Error claiming bond`)
        }
    })

    // Event Handlers

    const closeModal = (e?: any) => {
        show = false
        offBondClaimed?.()
        onClose?.()
    }

    useEffect(() => {
        if(claimBond.isSuccess){
            toast(`Claiming token ${App.TokenTicker}`)
            closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[claimBond.isSuccess])


    return (
        <Modal backdrop="static" size="lg" centered show={show} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title className="fs-5">
                    <i className="fa-solid fa-arrow-right-to-bracket"></i> CLAIM
                </Modal.Title>
                <Button onClick={closeModal} type="button" variant="" className="close-modal"><i className="fa-regular fa-circle-xmark"></i></Button>
            </Modal.Header>

            <Modal.Body>
                <ErrorBoundary fallback={<FallbackGenericError></FallbackGenericError>}>

                    <Row className="g-0">
                        <Col className="col-6">
                            <div className="box-title text-start">
                                <h5>Payout</h5>
                                <p>{formatLotsOfTokens(bond.amount)} {App.TokenTicker}</p>
                                <div className="bond-asset"><img src={bond.quoteTokenIconSvg} alt={bond.quoteTokenTicker}></img> </div>
                            </div>
                        </Col>
                        <Col className="col-6">
                            <div className="box-title text-end">
                                <h5>Term</h5>
                                <p>{formatDuration(bond.term)}</p>
                            </div>
                        </Col>
                    </Row>

                    <ul className="bond-details">
                        <li>
                            <span>Quote Token:</span>
                            <span>{bond.quoteTokenTicker}</span>
                        </li>
                        <li>
                            <span>You Will Get:</span>
                            <span>{formatLotsOfTokens(bond.amount)} {App.TokenTicker}</span>
                        </li>
                        <li>
                            <span>Term:</span>
                            <span>{formatDuration(bond.term)}</span>
                        </li>

                        <li>
                            <span>Status:</span>
                            <span>{bond.maturation < nowTimestampSeconds() ? "Claimable" : "Maturing"}</span>
                        </li>
                    </ul>

                    <Button className="block-button mt-3" disabled={!claimBondValidation || !claimBond.write || claimBond.isLoading || claimBond.isSuccess} onClick={(e) => claimBond.write?.()}>
                        {!claimBond.isLoading && !claimBond.isSuccess && (
                            <>CLAIM NOW</>
                        )}
                        {(claimBond.isLoading || claimBond.isSuccess) && (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />  New {App.TokenTicker} arriving...
                            </>
                        )}
                    </Button>
                </ErrorBoundary>
            </Modal.Body>
        </Modal>
    )
}
