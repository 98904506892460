import {fish} from "./fish"

export interface ClientTraceRequest {
    level: number
    key: string
    location: string
    data: any
}

export async function logEvent(event: ClientTraceRequest) {
    try {
        await fish.post("/client/log/event", event)
    } catch (error) {
        if (process.env.REACT_APP_TRACE_LEVEL_CONSOLE && parseInt(process.env.REACT_APP_TRACE_LEVEL_CONSOLE) > 0) {
            console.log("FailedRequest@hnApiLogEvent", error, event)
        }
    }    
}
