import { ReactElement } from "react"

export default function FallbackGenericError({ children }: { children?: ReactElement | ReactElement[] }) {
    return (
        <section className="section section-warning mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="warning-connection">
                            <i className="fa-solid fa-wallet"></i>
                            <h3>Error</h3>
                            {children && (
                                <>
                                    {children}
                                </>
                            )}
                            {!children && (
                                <p className="warning-message">Something went wrong. That's all we know for now.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
