const API_URL_FALLBACK = "https://api.hydranet.ai"
const API_URL_VERSION = "v1"

export const API_URL_ROOT = `${getApiUrl()}/${API_URL_VERSION}`

function getApiUrl(): string {
    if (process.env.REACT_APP_API_URL) {
        return process.env.REACT_APP_API_URL
    }

    let url = getWebRoot()
    if (!!url) {
        return `${url}`
    }

    console.log("getApiUrl@HydranetAPI", "Unable to determine API URL. Using fallback.")

    switch (process.env.REACT_APP_STAGE) {
        case "prod": return API_URL_FALLBACK
        case "staging": return API_URL_FALLBACK
        default: return "http://localhost:3000"
    }
}

function getWebRoot(): string | undefined {
    return window?.location.origin ?? process.env.PUBLIC_URL
}
