import { Link, Outlet } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CookieConsent from 'react-cookie-consent'

export default function layout() {
  return (
    <>
      <Header></Header>
      <main>
        <Outlet />
      </main>
      <Footer></Footer>
      <CookieConsent buttonText="I accept" buttonClasses="button-primary color-green btn btn-primary" overlay={true} disableButtonStyles={true}><h4>Hydranet Bonds are not available in United States</h4>Hydranet Bonds and Products are not available in United States due to local laws and regulations. You can still connect and access the page. If you do not reside in U.S. and you are not under the U.S. jurisdiction, you may continue using our products. This website uses cookies to enhance the user experience. By using our website, you agree to our use of cookies. <Link to="https://hydranet.ai/en/privacy-policy" target='_blank'>Learn more</Link></CookieConsent>
    </>
  )
}
