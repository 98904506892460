import { App } from "../constants"
import { nowTimestampSeconds } from "../utils/time"
import { formatDuration, formatLotsOfTokens} from "../utils/format"
import { bond } from "../shared/interfaces/bonds"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"


export default function BondMarketRow({ connected, bond, setSelectedBond }: { connected: boolean, bond: bond, setSelectedBond: (b: bond) => void }) {
    return (
        <ul>
            <li>
                <div className="bond-type">
                    <div className='asset-image'>  <img src={bond.quoteTokenIconSvg} alt={bond.quoteTokenTicker}></img></div>
                    <div className='asset-ticker'>
                        <p>{bond.quoteTokenTicker}</p>
                        <Link target="_blank" to={`https://arbiscan.io/address/${bond.quoteTokenAddress}`}>View Token-Contract</Link>
                    </div>
                </div>
            </li>
            <li><span className='xs-title'>Term:</span><span>{formatDuration(bond.term)}</span></li>
            <li><span className='xs-title'>Remaining:</span><span>{formatDuration((bond.maturation - nowTimestampSeconds()) ?? 0)}</span></li>
            <li><span className='xs-title'>Payout:</span><span>{formatLotsOfTokens(bond.amount)} {App.TokenTicker}</span></li>
            <li>
                <Button disabled={!connected || (bond.maturation - nowTimestampSeconds()) >= 0} onClick={e => setSelectedBond(bond)} className='button-primary color-green'>
                {bond && (bond.maturation - nowTimestampSeconds()) >= 0  ? ('PENDING') : ('CLAIM NOW') }
                </Button>
               
            </li>
        </ul>
    )
}
