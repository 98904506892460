import Arb from "../assets/token/arb.svg"
import Dai from "../assets/token/dai.svg"
import Ether from "../assets/token/ether.svg"
import Tether from "../assets/token/tether.svg"
import Usdc from "../assets/token/usdc.svg"
import { Tokens } from "../constants"

// TODO: externalize
const svg = new Map<string, string>([
    ["ARB", Arb],
    ["WETH", Ether],
    ["DAI", Dai],
    ["USDT", Tether],
    ["USDC", Usdc],
])
const digits = new Map<string, number>([
    ["ARB", 18],
    ["WETH", 18],
    ["DAI", 18],
    ["USDT", 6],
    ["USDC", 6],
])

const addressDigits = new Map<string, number>([
    ["0x912CE59144191C1204E64559FE8253a0e49E6548", 18],
    ["0x82aF49447D8a07e3bd95BD0d56f35241523fBab1", 18],
    ["0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1", 18],
    ["0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9", 6],
    ["0xaf88d065e77c8cC2239327C5EDb3A432268e5831", 6],
])

const defaultSvg = Ether

export function icon(token: string) {
    const s = svg.get(token)
    if (!s) {
        return defaultSvg
    }
    return s
}

export const ReverseTokenMap = new Map<number, Map<string,string>>()
Object.entries(Tokens).forEach(([token, v]) => {
    v.forEach((address, chain) => {
        const tokenMap = ReverseTokenMap.get(chain)
        if (tokenMap) {
            tokenMap.set(address, token)
            return
        }
        ReverseTokenMap.set(chain, new Map<string,string>([[address, token]]))
    })
})
    

export function token(chainId: number, address: string) {
    return ReverseTokenMap?.get(chainId)?.get(address) ?? "???"
}

export function tokenDigits(tokenTicker: string) {
    return digits.get(tokenTicker) ?? 18
}

export function tokenDigitsFromAddress(address: string){
    return addressDigits.get(address) ?? 18
}