import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  totalSupply: 0,
  lockedSupply: 0,
}

const tokenInfoSlice = createSlice({
  name: "tokenInfo",
  initialState,
  reducers: {
    updateSupply(state, action: { payload: { totalSupply: number, lockedSupply: number } }) {
      const { totalSupply, lockedSupply } = action.payload
      if (!totalSupply || !lockedSupply) {
        return
      }
      state.totalSupply = totalSupply
      state.lockedSupply = lockedSupply
    },
  },
})

export const { updateSupply } = tokenInfoSlice.actions
export default tokenInfoSlice.reducer
