import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { convertToToken } from "../utils/convert";
import { formatTimestamp } from "../utils/format";
import { trace, debug } from "../utils/trace";
import {
  bondMarketDetails,
  transformBondMarket,
  transformBondMarkets,
} from "../shared/interfaces/bonds";
import {
  updateBondMarkets,
  addBondMarket,
  removeBondMarket,
  updateBondMarketVolume,
} from "../store/slices/bondsSlice";
import { updateSupply } from "../store/slices/tokenInfoSlice";

// Web3
import { useChainId, useAccount } from "wagmi";
import {
  useInfoLockedSupply,
  useTokenTotalSupply,
  useBondageActiveMarkets,
  useBondageBondMarketOpenedEvent,
  useBondageBondMarketClosedEvent,
  useBondageBondSoldEvent,
  useBondageBondSaleStartDate,
} from "../web3/glue";

// Components
import { ToastContainer, toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InfoDashboardView from "../components/InfoDashboardView";
import BondMarketRow from "../components/BondMarketRow";
import BuyBondModal from "../components/BuyBondModal";

// Assets
import Date from "../assets/date.svg";
import InfoTableSection from "../components/InfoTableSection";
import { App } from "../constants";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const tokenInfo = useAppSelector((state) => state.tokenInfo);
  const bondMarkets = useAppSelector((state) => state.bonds.bondMarkets);
  const usdPrices = useAppSelector((state) => state.bonds.usdPrice);
  const [selectedMarket, setSelectedMarket] = useState(
    undefined as bondMarketDetails | undefined
  );
  const [totalSupply, setTotalSupply] = useState(0);
  const [lockedSupply, setLockedSupply] = useState(0);
  const [startDate, setStartDate] = useState(0);

  const chainId = useChainId();
  const account = useAccount();

  // Cache for processed events
  const processedBondSoldIds = new Set<number>();

  useTokenTotalSupply({
    onSuccess: (data) => {
      setTotalSupply(convertToToken(data, App.TokenDecimals));
    },
    onError: (error) => {
      trace("getTotalSupplyError", "dashboard", error);
      toast(`Error fetching total supply: ${error.message}`);
      // TODO: Proper error handling
    },
  });

  useInfoLockedSupply({
    onSuccess: (data) => {
      setLockedSupply(convertToToken(data, App.TokenDecimals));
    },
    onError: (error) => {
      trace("getLockedSupplyError", "dashboard", error);
      toast(`Error fetching locked supply: ${error.message}`);
      // TODO: Proper error handling
    },
  });

  useBondageBondSaleStartDate({
    onSuccess: (data) => {
      setStartDate(Number(data));
    },
    onError: (error) => {
      trace("getBondageSaleStartDateError", "dashboard", error);
      toast(`Error fetching bonds round start date: ${error.message}`);
      // TODO: Proper error handling
    },
  });


  useBondageActiveMarkets({
    onSuccess: (data) => {
      dispatch(
        updateBondMarkets({
          update: transformBondMarkets(data),
          chainId: chainId,
        })
      );
    },
    onError: (error) => {
      trace("getActiveMarketsError", "dashboard", error);
      toast(`Error fetching active bond markets: ${error.message}`);
      // TODO: Proper error handling
    },
  });

  const offBondageMarketOpened = useBondageBondMarketOpenedEvent({
    listener: (data) => {
      data.forEach((event) => {
        if (!event.args.market) {
          trace("invalidBondMarketOpenedEvent", "dashboard", event);
          return;
        }
        dispatch(
          addBondMarket({
            newMarket: transformBondMarket(event.args.market),
            chainId: chainId,
          })
        );
      });
    },
  });

  const offBondageMarketClosed = useBondageBondMarketClosedEvent({
    listener: (data) => {
      data.forEach((event) => {
        if (!event.args.market) {
          trace("invalidBondMarketClosedEvent", "dashboard", event);
          return;
        }
        dispatch(
          removeBondMarket({
            marketId: Number(event.args.market.id),
          })
        );
      });
    },
  });

  function isBondMarketAvailable(bondMarket: bondMarketDetails): boolean {
    // atleast one hdn is available
    return bondMarket.volume - bondMarket.sold > 1;
  }

  const offBondSold = useBondageBondSoldEvent({
    listener: async (data) => {
      data.forEach((event) => {
        if (!event.args.bond) {
          trace("invalidBondSoldEvent", "dashboard", event);
          return;
        }
        if (processedBondSoldIds.has(Number(event.args.bond.id))) {
          debug("duplicateBondSoldEvent", "dashboard", event);
          return;
        }
        debug("bondSoldEvent", "dashboard", event);

        processedBondSoldIds.add(Number(event.args.bond.id));
        dispatch(
          updateBondMarketVolume({
            marketId: Number(event.args.bond.marketId),
            soldAmount: convertToToken(event.args.bond.amount, App.TokenDecimals),
          })
        );
      });
    },
  });

  useEffect(() => {
    if (!totalSupply || !lockedSupply) {
      return;
    }
    dispatch(updateSupply({ totalSupply, lockedSupply }));
  }, [dispatch, totalSupply, lockedSupply]);

  useEffect(() => {
    const bms = bondMarkets.map((bm) => {
      return {
        ...bm,
        usdPrice: bm.quoteTokenTicker
          ? usdPrices[bm.quoteTokenTicker]
          : undefined,
      };
    });
    dispatch(updateBondMarkets({ update: bms, chainId: chainId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdPrices]);

  // Cleanup
  useEffect(
    () => {
      // Anything in here is fired on component mount.
      return () => {
        // Anything in here is fired on component unmount.
        offBondageMarketOpened?.();
        offBondageMarketClosed?.();
        offBondSold?.();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      
      <section className="section section-cards-2">
      { bondMarkets.length > 0 && 
        <InfoDashboardView
          bondMarkets={bondMarkets}
          supply={{
            total: tokenInfo.totalSupply,
            locked: tokenInfo.lockedSupply,
          }}
        ></InfoDashboardView>
    }
      </section>
      <section className="section section-filter mt-5">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg="auto">
              <h3 className="title">
                <i className="fa-solid fa-money-bill-trend-up"></i>{" "}
                <span>Active Bonds</span>
              </h3>
            </Col>
            <Col lg="auto" className="version-column">
              <ul className="version-info d-flex justify-content-end">
                {startDate > 0 && (
                  <li>
                    <img src={Date} alt="Chart"></img>Start Date:{" "}
                    {formatTimestamp(startDate)}
                  </li>
                )}
                {/* <li>
                  <img src={Update} alt="Chart"></img>Update:{" "}
                  {formatTimestamp(lastUpdateDate)}
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-filter-bonds mt-lg-3 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <div className="bond-list my-bonds">
                <ul className="bond-list-header">
                  <li>TYPE</li>
                  <li>PRICE</li>
                  <li>TOTAL / AVAILABLE</li>
                  <li>TERM</li>
                  <li>BUY</li>
                </ul>

                <div className="bond-list-body">
                {account.isConnected && bondMarkets.length > 0 ? (

                
                  bondMarkets.map((bondMarket, ix) => {
                    return (
                      <BondMarketRow
                        key={ix}
                        connected={account.isConnected}
                        bondMarket={bondMarket}
                        setSelectedBondMarket={setSelectedMarket}
                        soldOut={!isBondMarketAvailable(bondMarket)}
                      />
                    );
                  })
                  ) : account.isConnected ? (
                  <InfoTableSection title="No bonds available" >
                  <p> Currently we don't have any bonds available, please check later </p>
                  </InfoTableSection>) : (
                  <InfoTableSection title="No wallet connected" >
                  <p> As your wallet is not connected, we can not show available bonds </p>
                  </InfoTableSection>)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      {account.isConnected && selectedMarket && (
        <BuyBondModal
          bondMarket={selectedMarket}
          show={!!selectedMarket}
          onClose={() => setSelectedMarket(undefined)}
        ></BuyBondModal>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}
