import {fish} from "./fish"
import { trace } from "../../../utils/trace"

export interface PriceData {
    [tokenTicker: string]: number
}

export async function apiPriceUsd(tokens: Set<string>): Promise<Record<string, number> | undefined> {
    try {
        return await fish.get<Record<string, number>>(`/price${Array.from(tokens).reduce((a, b) => {return `${a}/${b}`}, "")}`)
    } catch (error) {
        trace("errorCf", "HydranetAPI.Price", `Unable to fetch price from Hydranet API. Error: ${error}`)
    }
}
