import { ReactElement } from "react"
import { useAccount } from "wagmi"

export default function Web3WalletNotConnected({ children, show }: { children?: ReactElement | ReactElement[], show?: boolean }) {
    const account = useAccount()
    return (
        <>
            {!account.isConnected && (
                <section className="section section-warning mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="warning-connection">
                                    <i className="fa-solid fa-wallet"></i>
                                    <h3>Wallet Not Connected</h3>
                                    {children && (
                                        <>
                                            {children}
                                        </>
                                    )}
                                    {!children && (
                                    <p className="warning-message">Go ahead and click the CONNECT button. (It's in the top right corner)</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}
