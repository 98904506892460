import React from "react"
import { bondMarketDetails } from "../shared/interfaces/bonds"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InfoSupply from "./InfoChartSupply"
import ActiveBonds from "./InfoChartActiveBonds"

export default function InfoDashboardView({ bondMarkets, supply }: { bondMarkets: bondMarketDetails[], supply: { total: number, locked: number } }) {
    return (
            <Container>
                <Row>
                    <Col lg="6">
                        <InfoSupply total={supply.total} locked={supply.locked}></InfoSupply>
                    </Col>
                    <Col lg="6">
                        <ActiveBonds bondMarkets={bondMarkets}></ActiveBonds>
                    </Col>
                </Row>
            </Container>
    )
}