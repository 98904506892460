import { Routes, Route } from "react-router-dom"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { WagmiConfig } from "wagmi"
import { ConnectKitProvider } from "connectkit"
import { Web3Config } from "./web3/config"
import { store } from "./store/store"

import Layout from "./pages/layout"
import Dashboard from "./pages/dashboard"
import MyBonds from "./pages/mybonds"
import Faqs from "./pages/faqs"

import { stry } from "./shared/tools/stry"
import { ErrorBoundary } from "./shared/tools/stry"
import FallbackGenericError from "./components/FallbackGenericError"

import "./App.css"
import "./icons.css"
import "react-toastify/dist/ReactToastify.css"

const SyRoutes = stry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <ErrorBoundary fallback={<FallbackGenericError></FallbackGenericError>}>
      <WagmiConfig config={Web3Config}>
        <ConnectKitProvider>
          <Provider store={store}>
            <BrowserRouter>
              <SyRoutes>
                <Route path="/" element={<Layout />} >
                  <Route index={true} element={<Dashboard />} />
                  <Route path="mybonds" element={<MyBonds />} />
                  <Route path="faqs" element={<Faqs />} />
                </Route>
              </SyRoutes>
            </BrowserRouter>
          </Provider>
        </ConnectKitProvider>
      </WagmiConfig>
    </ErrorBoundary>
  )
}

export default App 
