import React from "react"
// import { Routes } from "react-router-dom"
import { createRoot } from "react-dom/client"

import 'bootstrap/dist/css/bootstrap.css';
import "./index.css"

import { init } from "./shared/tools/init"
import App from "./App"


init()
const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode >,
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals() 
