import { useEffect, useState } from "react"
import { useChainId, useAccount } from "wagmi"
import { useAppDispatch, useAppSelector } from "../store/hook"
import { App } from "../constants"
import { Api } from "../shared/api"
import { updateChainId, updateAccount } from "../store/slices/accountSlice"
import { updateUsdPrices } from "../store/slices/bondsSlice"
import { eqSet } from "../utils/compare"


export default function BackgroundWorker() {
    const dispatch = useAppDispatch()

    const chainId = useChainId()
    const account = useAccount()

    const bondMarkets = useAppSelector(state => state.bonds.bondMarkets)
    const [tokens, setTokens] = useState<Set<string>>(new Set<string>([App.TokenTicker]))
    const getTokens = () => { return tokens }

    // Web3 Chain & Account State

    useEffect(() => {
        dispatch(
            updateChainId({
                chainId: chainId,
            })
        )
    }, [dispatch, chainId])

    useEffect(() => {
        dispatch(
            updateAccount({
                account: {
                    address: account.address ?? "",
                    isConnected: account.isConnected
                }
            })
        )
    }, [dispatch, account])

    // USD Prices for Bond Markets
    useEffect(() => {
        const tokensNew = new Set<string>([App.TokenTicker])
        for (const token of bondMarkets.map((m) => m.quoteTokenTicker)) {
            if (!!token) {
                tokensNew.add(token)
            }
        }
        if (tokens !== tokensNew) {
            setTokens(tokensNew)
        }
        Refresher.Instance(dispatch).usdPrices(() => tokensNew)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bondMarkets])

    // Refresh in Background: 
    //  - USD Prices

    useEffect(() => {
        const r = Refresher.Instance(dispatch)
        r.start(async () => {
            r.usdPrices(getTokens)
        })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    return (
        <></>
    )
}

type AppDispatcher = ReturnType<typeof useAppDispatch>

let Instance = null as null | Refresher
class Refresher {
    INTERVAL = 1000 * 60 // 1 minute

    static Instance(dispatch: AppDispatcher): Refresher {
        if (!Instance) {
            Instance = new Refresher(dispatch)
        }
        return Instance
    }

    dispatch: AppDispatcher
    schedule?: NodeJS.Timer
    lastUpdate = {} as Record<string, any>

    constructor(dispatch: AppDispatcher) {
        this.dispatch = dispatch
    }

    start(fn: (() => Promise<void>)) {
        this.schedule = setInterval(fn, this.INTERVAL)
    }

    stop() {
        if (this.schedule) {
            clearInterval(this.schedule)
        }
    }

    async usdPrices(getTokens: () => Set<string>): Promise<void> {
        const updateKey = "usdPrices"
        const tokens = getTokens()
        if (this.lastUpdate[updateKey] &&
            eqSet(tokens, this.lastUpdate[updateKey].tokens) &&
            Date.now() - this.lastUpdate[updateKey].timestamp < this.INTERVAL) {
            return
        }
        const prices = await Api.Price.USD(tokens)
        if (!!prices) {
            this.lastUpdate[updateKey] = { timestamp: Date.now(), tokens: tokens }
            this.dispatch(updateUsdPrices({ prices }))
        }
    }
}
