import { Duration } from "duration-converter";
import { debug } from "../utils/trace"

export const timestampToDate = (seconds: number) => {
  return new Date(seconds * 1000).toLocaleString();
};

export const DateToTimestamp = (date: Date) => {
  return (date.getTime() / 1000) | 0;
};

export const datesToDuration = (start: Date | number, end: Date | number) => {
  return Duration.between(start, end);
};

export const bigintToNumber = (big: bigint) => {
  return big > BigInt(Number.MIN_SAFE_INTEGER) &&
    big < BigInt(Number.MAX_SAFE_INTEGER)
    ? Number(big)
    : convertToETH(big);
};

// divide by 10^18
export const convertToETH = (big: bigint) => {
  return Number(big) / 10 ** 18;
};

// multiply by 10^18
export const convertFromETH = (big: bigint) => {
  return Number(big) * 10 ** 18;
};

// divide by 10^TOKEN_DECIMALS
export const convertToToken = (big: bigint, decimals: number): number => {
  return Number(big) / 10 ** decimals;
};

// multiply by 10^TOKEN_DECIMALS
export const convertFromToken = (amount: number, decimals: number): bigint => {
  try {
    return BigInt(amount * 10 ** decimals);
  } catch (e) {
    debug("on convert to token", "convert.ts", amount)
    return BigInt(0);
  }
};

// divide by 10^decimals
export const convertToDecimals = (big: bigint, decimals: number) => {
  return Number(big / BigInt(10) ** BigInt(decimals));
};

// divide by 10^decimals
export const convertFromDecimals = (big: bigint, decimals: number) => {
  return Number(big * BigInt(10) ** BigInt(decimals));
};
