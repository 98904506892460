import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Footer() {
  return (
    <div className='footer'>
      <Container>
        <Row className="flex justify-content-between">
          <Col lg="auto" className="col-12">
            <span> © All rights reserved. Hydranet DAO</span>
          </Col>
          <Col lg="auto" className="col-12">
            {/* // TODO: Link to Privacy Policy - Terms & Conditions */}
            <span> <a href="https://hydranet.ai/en/privacy-policy" rel="noreferrer" target="_blank" title="Privacy">Privacy Policy</a> - <a href="https://hydranet.ai/en/terms-and-conditions" rel="noreferrer" target="_blank" title="Terms">Terms & Conditions</a></span>
          </Col> 
        </Row>
      </Container>
    </div>
  )
}
