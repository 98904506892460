import {logEvent} from "./hydranet/log"
import {apiPriceUsd} from "./hydranet/price"

export const Api = {
    Log: {
        Event: logEvent
    },
    Price : {
        USD: apiPriceUsd
    }
}