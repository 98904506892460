// Generated by @wagmi/cli@1.3.0 on 8/28/2023 at 5:54:23 PM
import {
  useNetwork,
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  Address,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi'
import {
  ReadContractResult,
  WriteContractMode,
  PrepareWriteContractResult,
} from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Bondage
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export const bondageABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'error',
    inputs: [
      { name: 'market', internalType: 'uint256', type: 'uint256' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'quoteAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'BondBuyTransferFailed',
  },
  { type: 'error', inputs: [], name: 'BondIndexCorrupted' },
  {
    type: 'error',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'balance', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'BondMarketClosingInsufficientBalance',
  },
  {
    type: 'error',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'BondMarketClosingRefundTransferFailed',
  },
  {
    type: 'error',
    inputs: [{ name: 'volume', internalType: 'uint256', type: 'uint256' }],
    name: 'BondMarketFundingTransferFailed',
  },
  {
    type: 'error',
    inputs: [
      { name: 'id', internalType: 'uint256', type: 'uint256' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'volume', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'BondMarketInsufficientVolume',
  },
  {
    type: 'error',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'BondMarketNotActive',
  },
  {
    type: 'error',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'BondNotActive',
  },
  {
    type: 'error',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'BondNotMatured',
  },
  {
    type: 'error',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'BondNotOwnedByCaller',
  },
  {
    type: 'error',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'BondPayoutTransferFailed',
  },
  { type: 'error', inputs: [], name: 'BondSaleActive' },
  { type: 'error', inputs: [], name: 'BondSaleNotActive' },
  {
    type: 'error',
    inputs: [
      { name: 'requestedElevator', internalType: 'address', type: 'address' },
    ],
    name: 'NotAdmin',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'bond',
        internalType: 'struct Bondage.Bond',
        type: 'tuple',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'marketId', internalType: 'uint256', type: 'uint256' },
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'maturation', internalType: 'uint256', type: 'uint256' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'term', internalType: 'uint256', type: 'uint256' },
        ],
        indexed: false,
      },
    ],
    name: 'BondClaimed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'market',
        internalType: 'struct Bondage.BondMarket',
        type: 'tuple',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'duration', internalType: 'uint256', type: 'uint256' },
          { name: 'volume', internalType: 'uint256', type: 'uint256' },
          { name: 'sold', internalType: 'uint256', type: 'uint256' },
        ],
        indexed: false,
      },
    ],
    name: 'BondMarketClosed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'market',
        internalType: 'struct Bondage.BondMarket',
        type: 'tuple',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'duration', internalType: 'uint256', type: 'uint256' },
          { name: 'volume', internalType: 'uint256', type: 'uint256' },
          { name: 'sold', internalType: 'uint256', type: 'uint256' },
        ],
        indexed: false,
      },
    ],
    name: 'BondMarketCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'market',
        internalType: 'struct Bondage.BondMarket',
        type: 'tuple',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'duration', internalType: 'uint256', type: 'uint256' },
          { name: 'volume', internalType: 'uint256', type: 'uint256' },
          { name: 'sold', internalType: 'uint256', type: 'uint256' },
        ],
        indexed: false,
      },
    ],
    name: 'BondMarketDeleted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'market',
        internalType: 'struct Bondage.BondMarket',
        type: 'tuple',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'duration', internalType: 'uint256', type: 'uint256' },
          { name: 'volume', internalType: 'uint256', type: 'uint256' },
          { name: 'sold', internalType: 'uint256', type: 'uint256' },
        ],
        indexed: false,
      },
    ],
    name: 'BondMarketOpened',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'bond',
        internalType: 'struct Bondage.Bond',
        type: 'tuple',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'marketId', internalType: 'uint256', type: 'uint256' },
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'maturation', internalType: 'uint256', type: 'uint256' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'term', internalType: 'uint256', type: 'uint256' },
        ],
        indexed: false,
      },
    ],
    name: 'BondSold',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [],
    name: 'DefaultAdminDelayChangeCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newDelay',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
      {
        name: 'effectSchedule',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
    ],
    name: 'DefaultAdminDelayChangeScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [],
    name: 'DefaultAdminTransferCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'acceptSchedule',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
    ],
    name: 'DefaultAdminTransferScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Unpaused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MANAGER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'OPERATOR_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PAUSER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'UPGRADER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'acceptDefaultAdminTransfer',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'activeMarkets',
    outputs: [
      {
        name: '',
        internalType: 'struct Bondage.BondMarket[]',
        type: 'tuple[]',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'duration', internalType: 'uint256', type: 'uint256' },
          { name: 'volume', internalType: 'uint256', type: 'uint256' },
          { name: 'sold', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'allMaturingBonds',
    outputs: [
      {
        name: '',
        internalType: 'struct Bondage.Bond[]',
        type: 'tuple[]',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'marketId', internalType: 'uint256', type: 'uint256' },
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'maturation', internalType: 'uint256', type: 'uint256' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'term', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newAdmin', internalType: 'address', type: 'address' }],
    name: 'beginDefaultAdminTransfer',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'bondMarketClose',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'quoteToken', internalType: 'address', type: 'address' },
      { name: 'price', internalType: 'uint256', type: 'uint256' },
      { name: 'duration', internalType: 'uint256', type: 'uint256' },
      { name: 'volume', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'bondSaleAdd',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'bondSaleClose',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'bondSaleNew',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'bondSaleStart',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'bondSaleStartDate',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'bondSaleViewStaging',
    outputs: [
      {
        name: '',
        internalType: 'struct Bondage.BondMarket[]',
        type: 'tuple[]',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'duration', internalType: 'uint256', type: 'uint256' },
          { name: 'volume', internalType: 'uint256', type: 'uint256' },
          { name: 'sold', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'marketId', internalType: 'uint256', type: 'uint256' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'buyBond',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelDefaultAdminTransfer',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newDelay', internalType: 'uint48', type: 'uint48' }],
    name: 'changeDefaultAdminDelay',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'bondId', internalType: 'uint256', type: 'uint256' }],
    name: 'claimBond',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'bondIds', internalType: 'uint256[]', type: 'uint256[]' }],
    name: 'claimBonds',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'claimableBonds',
    outputs: [
      {
        name: '',
        internalType: 'struct Bondage.Bond[]',
        type: 'tuple[]',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'marketId', internalType: 'uint256', type: 'uint256' },
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'maturation', internalType: 'uint256', type: 'uint256' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'term', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultAdmin',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultAdminDelay',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultAdminDelayIncreaseWait',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultElevationDuration',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'elevatedAddress', internalType: 'address', type: 'address' },
      { name: 'duration', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'elevate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'elevatedAddress', internalType: 'address', type: 'address' },
    ],
    name: 'elevate',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getRoleMember',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleMemberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'housekeeping',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'admin', internalType: 'address', type: 'address' },
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'treasury', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'maturingBonds',
    outputs: [
      {
        name: '',
        internalType: 'struct Bondage.Bond[]',
        type: 'tuple[]',
        components: [
          { name: 'id', internalType: 'uint256', type: 'uint256' },
          { name: 'marketId', internalType: 'uint256', type: 'uint256' },
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'maturation', internalType: 'uint256', type: 'uint256' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'price', internalType: 'uint256', type: 'uint256' },
          { name: 'quoteToken', internalType: 'address', type: 'address' },
          { name: 'term', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'pause',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pendingDefaultAdmin',
    outputs: [
      { name: 'newAdmin', internalType: 'address', type: 'address' },
      { name: 'schedule', internalType: 'uint48', type: 'uint48' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pendingDefaultAdminDelay',
    outputs: [
      { name: 'newDelay', internalType: 'uint48', type: 'uint48' },
      { name: 'schedule', internalType: 'uint48', type: 'uint48' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'admin', internalType: 'address', type: 'address' },
      { name: 'duration', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'requestElevation',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'admin', internalType: 'address', type: 'address' },
    ],
    name: 'requestElevation',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'rollbackDefaultAdminDelay',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'duration', internalType: 'uint48', type: 'uint48' }],
    name: 'setDefaultElevationDuration',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'unpause',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export const bondageAddress = {
  42161: '0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7',
  421613: '0xc7e1D8c2d53D74346cD92D97299E3505B25908b1',
} as const

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export const bondageConfig = {
  address: bondageAddress,
  abi: bondageABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Info
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export const infoABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'error',
    inputs: [
      { name: 'requestedElevator', internalType: 'address', type: 'address' },
    ],
    name: 'NotAdmin',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [],
    name: 'DefaultAdminDelayChangeCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newDelay',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
      {
        name: 'effectSchedule',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
    ],
    name: 'DefaultAdminDelayChangeScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [],
    name: 'DefaultAdminTransferCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'acceptSchedule',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
    ],
    name: 'DefaultAdminTransferScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Unpaused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PAUSER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'UPGRADER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: '_token',
    outputs: [{ name: '', internalType: 'contract Token', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: '_treasury',
    outputs: [{ name: '', internalType: 'contract Treasury', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'acceptDefaultAdminTransfer',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'addReservedAccount',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newAdmin', internalType: 'address', type: 'address' }],
    name: 'beginDefaultAdminTransfer',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelDefaultAdminTransfer',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newDelay', internalType: 'uint48', type: 'uint48' }],
    name: 'changeDefaultAdminDelay',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'circulatingSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultAdmin',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultAdminDelay',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultAdminDelayIncreaseWait',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'defaultElevationDuration',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'elevatedAddress', internalType: 'address', type: 'address' },
      { name: 'duration', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'elevate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'elevatedAddress', internalType: 'address', type: 'address' },
    ],
    name: 'elevate',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getRoleMember',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleMemberCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'housekeeping',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'admin', internalType: 'address', type: 'address' },
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'treasury', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'lockedSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'pause',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pendingDefaultAdmin',
    outputs: [
      { name: 'newAdmin', internalType: 'address', type: 'address' },
      { name: 'schedule', internalType: 'uint48', type: 'uint48' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pendingDefaultAdminDelay',
    outputs: [
      { name: 'newDelay', internalType: 'uint48', type: 'uint48' },
      { name: 'schedule', internalType: 'uint48', type: 'uint48' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'removeReservedAccount',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'admin', internalType: 'address', type: 'address' },
      { name: 'duration', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'requestElevation',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'admin', internalType: 'address', type: 'address' },
    ],
    name: 'requestElevation',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'reservedAccounts',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'rollbackDefaultAdminDelay',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'duration', internalType: 'uint48', type: 'uint48' }],
    name: 'setDefaultElevationDuration',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'token', internalType: 'address', type: 'address' }],
    name: 'setToken',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'treasury', internalType: 'address', type: 'address' }],
    name: 'setTreasury',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'unpause',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export const infoAddress = {
  42161: '0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB',
  421613: '0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5',
} as const

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export const infoConfig = { address: infoAddress, abi: infoABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Token
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export const tokenABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'error',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'BurnDisabled',
  },
  {
    type: 'error',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'MintDisabled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'delegator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'fromDelegate',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'toDelegate',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'DelegateChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'delegate',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'previousBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'DelegateVotesChanged',
  },
  { type: 'event', anonymous: false, inputs: [], name: 'EIP712DomainChanged' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Unpaused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'CLOCK_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MINTER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PAUSER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'UPGRADER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'pos', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'checkpoints',
    outputs: [
      {
        name: '',
        internalType: 'struct ERC20VotesUpgradeable.Checkpoint',
        type: 'tuple',
        components: [
          { name: 'fromBlock', internalType: 'uint32', type: 'uint32' },
          { name: 'votes', internalType: 'uint224', type: 'uint224' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'clock',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'delegatee', internalType: 'address', type: 'address' }],
    name: 'delegate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'delegatee', internalType: 'address', type: 'address' },
      { name: 'nonce', internalType: 'uint256', type: 'uint256' },
      { name: 'expiry', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'delegateBySig',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'delegates',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'eip712Domain',
    outputs: [
      { name: 'fields', internalType: 'bytes1', type: 'bytes1' },
      { name: 'name', internalType: 'string', type: 'string' },
      { name: 'version', internalType: 'string', type: 'string' },
      { name: 'chainId', internalType: 'uint256', type: 'uint256' },
      { name: 'verifyingContract', internalType: 'address', type: 'address' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
      { name: 'extensions', internalType: 'uint256[]', type: 'uint256[]' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'timepoint', internalType: 'uint256', type: 'uint256' }],
    name: 'getPastTotalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'timepoint', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPastVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'admin', internalType: 'address', type: 'address' },
      { name: 'treasury', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'numCheckpoints',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'pause',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'unpause',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export const tokenAddress = {
  42161: '0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506',
  421613: '0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A',
} as const

/**
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export const tokenConfig = { address: tokenAddress, abi: tokenABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TokenTestnet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const tokenTestnetABI = [
  {
    type: 'error',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'BurnDisabled',
  },
  {
    type: 'error',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'MintDisabled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'delegator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'fromDelegate',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'toDelegate',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'DelegateChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'delegate',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'previousBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'DelegateVotesChanged',
  },
  { type: 'event', anonymous: false, inputs: [], name: 'EIP712DomainChanged' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Unpaused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'CLOCK_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MINTER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PAUSER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'UPGRADER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'pos', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'checkpoints',
    outputs: [
      {
        name: '',
        internalType: 'struct ERC20VotesUpgradeable.Checkpoint',
        type: 'tuple',
        components: [
          { name: 'fromBlock', internalType: 'uint32', type: 'uint32' },
          { name: 'votes', internalType: 'uint224', type: 'uint224' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'clock',
    outputs: [{ name: '', internalType: 'uint48', type: 'uint48' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'delegatee', internalType: 'address', type: 'address' }],
    name: 'delegate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'delegatee', internalType: 'address', type: 'address' },
      { name: 'nonce', internalType: 'uint256', type: 'uint256' },
      { name: 'expiry', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'delegateBySig',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'delegates',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'eip712Domain',
    outputs: [
      { name: 'fields', internalType: 'bytes1', type: 'bytes1' },
      { name: 'name', internalType: 'string', type: 'string' },
      { name: 'version', internalType: 'string', type: 'string' },
      { name: 'chainId', internalType: 'uint256', type: 'uint256' },
      { name: 'verifyingContract', internalType: 'address', type: 'address' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
      { name: 'extensions', internalType: 'uint256[]', type: 'uint256[]' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'timepoint', internalType: 'uint256', type: 'uint256' }],
    name: 'getPastTotalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'timepoint', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPastVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'admin', internalType: 'address', type: 'address' },
      { name: 'treasury', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'numCheckpoints',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'pause',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'unpause',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20ABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20Upgradeable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20UpgradeableABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20Metadata
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20MetadataABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20MetadataUpgradeable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20MetadataUpgradeableABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20PermitUpgradeable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20PermitUpgradeableABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"ADMIN_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageAdminRole<
  TFunctionName extends 'ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"MANAGER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageManagerRole<
  TFunctionName extends 'MANAGER_ROLE',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'MANAGER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"OPERATOR_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageOperatorRole<
  TFunctionName extends 'OPERATOR_ROLE',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'OPERATOR_ROLE',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"PAUSER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondagePauserRole<
  TFunctionName extends 'PAUSER_ROLE',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'PAUSER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"UPGRADER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageUpgraderRole<
  TFunctionName extends 'UPGRADER_ROLE',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'UPGRADER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"activeMarkets"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageActiveMarkets<
  TFunctionName extends 'activeMarkets',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'activeMarkets',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"allMaturingBonds"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageAllMaturingBonds<
  TFunctionName extends 'allMaturingBonds',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'allMaturingBonds',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleStartDate"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondSaleStartDate<
  TFunctionName extends 'bondSaleStartDate',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleStartDate',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleViewStaging"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondSaleViewStaging<
  TFunctionName extends 'bondSaleViewStaging',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleViewStaging',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"claimableBonds"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageClaimableBonds<
  TFunctionName extends 'claimableBonds',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'claimableBonds',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"defaultAdmin"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdmin<
  TFunctionName extends 'defaultAdmin',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'defaultAdmin',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"defaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdminDelay<
  TFunctionName extends 'defaultAdminDelay',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'defaultAdminDelay',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"defaultAdminDelayIncreaseWait"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdminDelayIncreaseWait<
  TFunctionName extends 'defaultAdminDelayIncreaseWait',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'defaultAdminDelayIncreaseWait',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"defaultElevationDuration"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultElevationDuration<
  TFunctionName extends 'defaultElevationDuration',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'defaultElevationDuration',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"getRoleAdmin"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"getRoleMember"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageGetRoleMember<
  TFunctionName extends 'getRoleMember',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'getRoleMember',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"getRoleMemberCount"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageGetRoleMemberCount<
  TFunctionName extends 'getRoleMemberCount',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'getRoleMemberCount',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"hasRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"maturingBonds"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageMaturingBonds<
  TFunctionName extends 'maturingBonds',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'maturingBonds',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"owner"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"paused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondagePaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"pendingDefaultAdmin"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondagePendingDefaultAdmin<
  TFunctionName extends 'pendingDefaultAdmin',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'pendingDefaultAdmin',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"pendingDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondagePendingDefaultAdminDelay<
  TFunctionName extends 'pendingDefaultAdminDelay',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'pendingDefaultAdminDelay',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof bondageABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof bondageABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof bondageABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof bondageABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, TFunctionName, TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"acceptDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageAcceptDefaultAdminTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'acceptDefaultAdminTransfer'
        >['request']['abi'],
        'acceptDefaultAdminTransfer',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'acceptDefaultAdminTransfer'
      }
    : UseContractWriteConfig<
        typeof bondageABI,
        'acceptDefaultAdminTransfer',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'acceptDefaultAdminTransfer'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<
    typeof bondageABI,
    'acceptDefaultAdminTransfer',
    TMode
  >({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'acceptDefaultAdminTransfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"beginDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBeginDefaultAdminTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'beginDefaultAdminTransfer'
        >['request']['abi'],
        'beginDefaultAdminTransfer',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'beginDefaultAdminTransfer'
      }
    : UseContractWriteConfig<
        typeof bondageABI,
        'beginDefaultAdminTransfer',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'beginDefaultAdminTransfer'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<
    typeof bondageABI,
    'beginDefaultAdminTransfer',
    TMode
  >({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'beginDefaultAdminTransfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondMarketClose"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondMarketClose<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'bondMarketClose'
        >['request']['abi'],
        'bondMarketClose',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'bondMarketClose'
      }
    : UseContractWriteConfig<typeof bondageABI, 'bondMarketClose', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'bondMarketClose'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'bondMarketClose', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondMarketClose',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleAdd"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondSaleAdd<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'bondSaleAdd'
        >['request']['abi'],
        'bondSaleAdd',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'bondSaleAdd'
      }
    : UseContractWriteConfig<typeof bondageABI, 'bondSaleAdd', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'bondSaleAdd'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'bondSaleAdd', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleAdd',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleClose"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondSaleClose<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'bondSaleClose'
        >['request']['abi'],
        'bondSaleClose',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'bondSaleClose'
      }
    : UseContractWriteConfig<typeof bondageABI, 'bondSaleClose', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'bondSaleClose'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'bondSaleClose', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleClose',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleNew"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondSaleNew<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'bondSaleNew'
        >['request']['abi'],
        'bondSaleNew',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'bondSaleNew'
      }
    : UseContractWriteConfig<typeof bondageABI, 'bondSaleNew', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'bondSaleNew'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'bondSaleNew', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleNew',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleStart"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondSaleStart<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'bondSaleStart'
        >['request']['abi'],
        'bondSaleStart',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'bondSaleStart'
      }
    : UseContractWriteConfig<typeof bondageABI, 'bondSaleStart', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'bondSaleStart'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'bondSaleStart', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleStart',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"buyBond"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBuyBond<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'buyBond'
        >['request']['abi'],
        'buyBond',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'buyBond' }
    : UseContractWriteConfig<typeof bondageABI, 'buyBond', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'buyBond'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'buyBond', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'buyBond',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"cancelDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageCancelDefaultAdminTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'cancelDefaultAdminTransfer'
        >['request']['abi'],
        'cancelDefaultAdminTransfer',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'cancelDefaultAdminTransfer'
      }
    : UseContractWriteConfig<
        typeof bondageABI,
        'cancelDefaultAdminTransfer',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'cancelDefaultAdminTransfer'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<
    typeof bondageABI,
    'cancelDefaultAdminTransfer',
    TMode
  >({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'cancelDefaultAdminTransfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"changeDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageChangeDefaultAdminDelay<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'changeDefaultAdminDelay'
        >['request']['abi'],
        'changeDefaultAdminDelay',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'changeDefaultAdminDelay'
      }
    : UseContractWriteConfig<
        typeof bondageABI,
        'changeDefaultAdminDelay',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeDefaultAdminDelay'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'changeDefaultAdminDelay', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'changeDefaultAdminDelay',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"claimBond"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageClaimBond<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'claimBond'
        >['request']['abi'],
        'claimBond',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'claimBond' }
    : UseContractWriteConfig<typeof bondageABI, 'claimBond', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'claimBond'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'claimBond', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'claimBond',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"claimBonds"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageClaimBonds<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'claimBonds'
        >['request']['abi'],
        'claimBonds',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'claimBonds' }
    : UseContractWriteConfig<typeof bondageABI, 'claimBonds', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'claimBonds'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'claimBonds', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'claimBonds',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"elevate"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageElevate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'elevate'
        >['request']['abi'],
        'elevate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'elevate' }
    : UseContractWriteConfig<typeof bondageABI, 'elevate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'elevate'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'elevate', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'elevate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageGrantRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof bondageABI, 'grantRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'grantRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'grantRole', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'grantRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"housekeeping"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageHousekeeping<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'housekeeping'
        >['request']['abi'],
        'housekeeping',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'housekeeping'
      }
    : UseContractWriteConfig<typeof bondageABI, 'housekeeping', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'housekeeping'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'housekeeping', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'housekeeping',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'initialize'
        >['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof bondageABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'initialize', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"pause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondagePause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'pause'
        >['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof bondageABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'pause', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRenounceRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'renounceRole'
      }
    : UseContractWriteConfig<typeof bondageABI, 'renounceRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'renounceRole', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'renounceRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"requestElevation"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRequestElevation<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'requestElevation'
        >['request']['abi'],
        'requestElevation',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'requestElevation'
      }
    : UseContractWriteConfig<typeof bondageABI, 'requestElevation', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'requestElevation'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'requestElevation', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'requestElevation',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRevokeRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof bondageABI, 'revokeRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'revokeRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'revokeRole', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'revokeRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"rollbackDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRollbackDefaultAdminDelay<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'rollbackDefaultAdminDelay'
        >['request']['abi'],
        'rollbackDefaultAdminDelay',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'rollbackDefaultAdminDelay'
      }
    : UseContractWriteConfig<
        typeof bondageABI,
        'rollbackDefaultAdminDelay',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'rollbackDefaultAdminDelay'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<
    typeof bondageABI,
    'rollbackDefaultAdminDelay',
    TMode
  >({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'rollbackDefaultAdminDelay',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"setDefaultElevationDuration"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageSetDefaultElevationDuration<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'setDefaultElevationDuration'
        >['request']['abi'],
        'setDefaultElevationDuration',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setDefaultElevationDuration'
      }
    : UseContractWriteConfig<
        typeof bondageABI,
        'setDefaultElevationDuration',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDefaultElevationDuration'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<
    typeof bondageABI,
    'setDefaultElevationDuration',
    TMode
  >({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'setDefaultElevationDuration',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"unpause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'unpause'
        >['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof bondageABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'unpause', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"upgradeTo"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageUpgradeTo<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'upgradeTo'
        >['request']['abi'],
        'upgradeTo',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeTo' }
    : UseContractWriteConfig<typeof bondageABI, 'upgradeTo', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeTo'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'upgradeTo', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'upgradeTo',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof bondageAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof bondageABI,
          'upgradeToAndCall'
        >['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      }
    : UseContractWriteConfig<typeof bondageABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof bondageABI, 'upgradeToAndCall', TMode>({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"acceptDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageAcceptDefaultAdminTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof bondageABI,
      'acceptDefaultAdminTransfer'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'acceptDefaultAdminTransfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof bondageABI,
    'acceptDefaultAdminTransfer'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"beginDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageBeginDefaultAdminTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof bondageABI,
      'beginDefaultAdminTransfer'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'beginDefaultAdminTransfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof bondageABI,
    'beginDefaultAdminTransfer'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondMarketClose"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageBondMarketClose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'bondMarketClose'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondMarketClose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'bondMarketClose'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleAdd"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageBondSaleAdd(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleAdd'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleAdd',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleAdd'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleClose"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageBondSaleClose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleClose'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleClose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleClose'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleNew"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageBondSaleNew(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleNew'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleNew',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleNew'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"bondSaleStart"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageBondSaleStart(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleStart'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'bondSaleStart',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'bondSaleStart'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"buyBond"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageBuyBond(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'buyBond'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'buyBond',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'buyBond'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"cancelDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageCancelDefaultAdminTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof bondageABI,
      'cancelDefaultAdminTransfer'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'cancelDefaultAdminTransfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof bondageABI,
    'cancelDefaultAdminTransfer'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"changeDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageChangeDefaultAdminDelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'changeDefaultAdminDelay'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'changeDefaultAdminDelay',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof bondageABI,
    'changeDefaultAdminDelay'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"claimBond"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageClaimBond(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'claimBond'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'claimBond',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'claimBond'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"claimBonds"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageClaimBonds(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'claimBonds'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'claimBonds',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'claimBonds'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"elevate"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageElevate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'elevate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'elevate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'elevate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'grantRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'grantRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"housekeeping"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageHousekeeping(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'housekeeping'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'housekeeping',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'housekeeping'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"pause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondagePause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'renounceRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'renounceRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"requestElevation"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageRequestElevation(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'requestElevation'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'requestElevation',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'requestElevation'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'revokeRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'revokeRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"rollbackDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageRollbackDefaultAdminDelay(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof bondageABI,
      'rollbackDefaultAdminDelay'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'rollbackDefaultAdminDelay',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof bondageABI,
    'rollbackDefaultAdminDelay'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"setDefaultElevationDuration"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageSetDefaultElevationDuration(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof bondageABI,
      'setDefaultElevationDuration'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'setDefaultElevationDuration',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof bondageABI,
    'setDefaultElevationDuration'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"unpause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"upgradeTo"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageUpgradeTo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'upgradeTo'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'upgradeTo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'upgradeTo'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link bondageABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function usePrepareBondageUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof bondageABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof bondageABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    ...config,
  } as UseContractEventConfig<typeof bondageABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"AdminChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'AdminChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'AdminChanged',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'AdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"BeaconUpgraded"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBeaconUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'BeaconUpgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'BeaconUpgraded',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'BeaconUpgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"BondClaimed"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondClaimedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'BondClaimed'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'BondClaimed',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'BondClaimed'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"BondMarketClosed"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondMarketClosedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'BondMarketClosed'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'BondMarketClosed',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'BondMarketClosed'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"BondMarketCreated"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondMarketCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'BondMarketCreated'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'BondMarketCreated',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'BondMarketCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"BondMarketDeleted"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondMarketDeletedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'BondMarketDeleted'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'BondMarketDeleted',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'BondMarketDeleted'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"BondMarketOpened"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondMarketOpenedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'BondMarketOpened'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'BondMarketOpened',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'BondMarketOpened'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"BondSold"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageBondSoldEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'BondSold'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'BondSold',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'BondSold'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"DefaultAdminDelayChangeCanceled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdminDelayChangeCanceledEvent(
  config: Omit<
    UseContractEventConfig<
      typeof bondageABI,
      'DefaultAdminDelayChangeCanceled'
    >,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'DefaultAdminDelayChangeCanceled',
    ...config,
  } as UseContractEventConfig<
    typeof bondageABI,
    'DefaultAdminDelayChangeCanceled'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"DefaultAdminDelayChangeScheduled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdminDelayChangeScheduledEvent(
  config: Omit<
    UseContractEventConfig<
      typeof bondageABI,
      'DefaultAdminDelayChangeScheduled'
    >,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'DefaultAdminDelayChangeScheduled',
    ...config,
  } as UseContractEventConfig<
    typeof bondageABI,
    'DefaultAdminDelayChangeScheduled'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"DefaultAdminTransferCanceled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdminTransferCanceledEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'DefaultAdminTransferCanceled'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'DefaultAdminTransferCanceled',
    ...config,
  } as UseContractEventConfig<
    typeof bondageABI,
    'DefaultAdminTransferCanceled'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"DefaultAdminTransferScheduled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageDefaultAdminTransferScheduledEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'DefaultAdminTransferScheduled'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'DefaultAdminTransferScheduled',
    ...config,
  } as UseContractEventConfig<
    typeof bondageABI,
    'DefaultAdminTransferScheduled'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"Initialized"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"Paused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondagePausedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"RoleAdminChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRoleAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'RoleAdminChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'RoleAdminChanged',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'RoleAdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"RoleGranted"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRoleGrantedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'RoleGranted'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'RoleGranted',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'RoleGranted'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"RoleRevoked"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageRoleRevokedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'RoleRevoked'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'RoleRevoked',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'RoleRevoked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"Unpaused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link bondageABI}__ and `eventName` set to `"Upgraded"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x15740F6057b5427C2155FdA85ed5E9AaAb3bF3e7)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xc7e1D8c2d53D74346cD92D97299E3505B25908b1)
 */
export function useBondageUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof bondageABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof bondageAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: bondageABI,
    address: bondageAddress[chainId as keyof typeof bondageAddress],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof bondageABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"ADMIN_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoAdminRole<
  TFunctionName extends 'ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"PAUSER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoPauserRole<
  TFunctionName extends 'PAUSER_ROLE',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'PAUSER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"UPGRADER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoUpgraderRole<
  TFunctionName extends 'UPGRADER_ROLE',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'UPGRADER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"_token"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoToken<
  TFunctionName extends '_token',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: '_token',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"_treasury"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoTreasury<
  TFunctionName extends '_treasury',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: '_treasury',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"circulatingSupply"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoCirculatingSupply<
  TFunctionName extends 'circulatingSupply',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'circulatingSupply',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"defaultAdmin"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdmin<
  TFunctionName extends 'defaultAdmin',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'defaultAdmin',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"defaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdminDelay<
  TFunctionName extends 'defaultAdminDelay',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'defaultAdminDelay',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"defaultAdminDelayIncreaseWait"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdminDelayIncreaseWait<
  TFunctionName extends 'defaultAdminDelayIncreaseWait',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'defaultAdminDelayIncreaseWait',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"defaultElevationDuration"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultElevationDuration<
  TFunctionName extends 'defaultElevationDuration',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'defaultElevationDuration',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"getRoleAdmin"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"getRoleMember"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoGetRoleMember<
  TFunctionName extends 'getRoleMember',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'getRoleMember',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"getRoleMemberCount"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoGetRoleMemberCount<
  TFunctionName extends 'getRoleMemberCount',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'getRoleMemberCount',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"hasRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"lockedSupply"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoLockedSupply<
  TFunctionName extends 'lockedSupply',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'lockedSupply',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"owner"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"paused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"pendingDefaultAdmin"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoPendingDefaultAdmin<
  TFunctionName extends 'pendingDefaultAdmin',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'pendingDefaultAdmin',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"pendingDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoPendingDefaultAdminDelay<
  TFunctionName extends 'pendingDefaultAdminDelay',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'pendingDefaultAdminDelay',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"reservedAccounts"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoReservedAccounts<
  TFunctionName extends 'reservedAccounts',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'reservedAccounts',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof infoABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof infoABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof infoABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof infoABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, TFunctionName, TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"acceptDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoAcceptDefaultAdminTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'acceptDefaultAdminTransfer'
        >['request']['abi'],
        'acceptDefaultAdminTransfer',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'acceptDefaultAdminTransfer'
      }
    : UseContractWriteConfig<
        typeof infoABI,
        'acceptDefaultAdminTransfer',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'acceptDefaultAdminTransfer'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'acceptDefaultAdminTransfer', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'acceptDefaultAdminTransfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"addReservedAccount"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoAddReservedAccount<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'addReservedAccount'
        >['request']['abi'],
        'addReservedAccount',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'addReservedAccount'
      }
    : UseContractWriteConfig<typeof infoABI, 'addReservedAccount', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'addReservedAccount'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'addReservedAccount', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'addReservedAccount',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"beginDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoBeginDefaultAdminTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'beginDefaultAdminTransfer'
        >['request']['abi'],
        'beginDefaultAdminTransfer',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'beginDefaultAdminTransfer'
      }
    : UseContractWriteConfig<
        typeof infoABI,
        'beginDefaultAdminTransfer',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'beginDefaultAdminTransfer'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'beginDefaultAdminTransfer', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'beginDefaultAdminTransfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"cancelDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoCancelDefaultAdminTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'cancelDefaultAdminTransfer'
        >['request']['abi'],
        'cancelDefaultAdminTransfer',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'cancelDefaultAdminTransfer'
      }
    : UseContractWriteConfig<
        typeof infoABI,
        'cancelDefaultAdminTransfer',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'cancelDefaultAdminTransfer'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'cancelDefaultAdminTransfer', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'cancelDefaultAdminTransfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"changeDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoChangeDefaultAdminDelay<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'changeDefaultAdminDelay'
        >['request']['abi'],
        'changeDefaultAdminDelay',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'changeDefaultAdminDelay'
      }
    : UseContractWriteConfig<
        typeof infoABI,
        'changeDefaultAdminDelay',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeDefaultAdminDelay'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'changeDefaultAdminDelay', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'changeDefaultAdminDelay',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"elevate"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoElevate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof infoABI, 'elevate'>['request']['abi'],
        'elevate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'elevate' }
    : UseContractWriteConfig<typeof infoABI, 'elevate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'elevate'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'elevate', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'elevate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoGrantRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof infoABI, 'grantRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'grantRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'grantRole', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'grantRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"housekeeping"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoHousekeeping<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'housekeeping'
        >['request']['abi'],
        'housekeeping',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'housekeeping'
      }
    : UseContractWriteConfig<typeof infoABI, 'housekeeping', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'housekeeping'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'housekeeping', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'housekeeping',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'initialize'
        >['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof infoABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'initialize', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"pause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof infoABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof infoABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'pause', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"removeReservedAccount"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRemoveReservedAccount<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'removeReservedAccount'
        >['request']['abi'],
        'removeReservedAccount',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'removeReservedAccount'
      }
    : UseContractWriteConfig<typeof infoABI, 'removeReservedAccount', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'removeReservedAccount'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'removeReservedAccount', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'removeReservedAccount',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRenounceRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'renounceRole'
      }
    : UseContractWriteConfig<typeof infoABI, 'renounceRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'renounceRole', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'renounceRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"requestElevation"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRequestElevation<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'requestElevation'
        >['request']['abi'],
        'requestElevation',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'requestElevation'
      }
    : UseContractWriteConfig<typeof infoABI, 'requestElevation', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'requestElevation'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'requestElevation', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'requestElevation',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRevokeRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof infoABI, 'revokeRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'revokeRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'revokeRole', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'revokeRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"rollbackDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRollbackDefaultAdminDelay<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'rollbackDefaultAdminDelay'
        >['request']['abi'],
        'rollbackDefaultAdminDelay',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'rollbackDefaultAdminDelay'
      }
    : UseContractWriteConfig<
        typeof infoABI,
        'rollbackDefaultAdminDelay',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'rollbackDefaultAdminDelay'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'rollbackDefaultAdminDelay', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'rollbackDefaultAdminDelay',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"setDefaultElevationDuration"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoSetDefaultElevationDuration<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'setDefaultElevationDuration'
        >['request']['abi'],
        'setDefaultElevationDuration',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setDefaultElevationDuration'
      }
    : UseContractWriteConfig<
        typeof infoABI,
        'setDefaultElevationDuration',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDefaultElevationDuration'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'setDefaultElevationDuration', TMode>(
    {
      abi: infoABI,
      address: infoAddress[chainId as keyof typeof infoAddress],
      functionName: 'setDefaultElevationDuration',
      ...config,
    } as any,
  )
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"setToken"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoSetToken<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'setToken'
        >['request']['abi'],
        'setToken',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setToken' }
    : UseContractWriteConfig<typeof infoABI, 'setToken', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setToken'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'setToken', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'setToken',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"setTreasury"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoSetTreasury<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'setTreasury'
        >['request']['abi'],
        'setTreasury',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setTreasury'
      }
    : UseContractWriteConfig<typeof infoABI, 'setTreasury', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setTreasury'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'setTreasury', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'setTreasury',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"unpause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof infoABI, 'unpause'>['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof infoABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'unpause', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"upgradeTo"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoUpgradeTo<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'upgradeTo'
        >['request']['abi'],
        'upgradeTo',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeTo' }
    : UseContractWriteConfig<typeof infoABI, 'upgradeTo', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeTo'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'upgradeTo', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'upgradeTo',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof infoAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof infoABI,
          'upgradeToAndCall'
        >['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      }
    : UseContractWriteConfig<typeof infoABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof infoABI, 'upgradeToAndCall', TMode>({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"acceptDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoAcceptDefaultAdminTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'acceptDefaultAdminTransfer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'acceptDefaultAdminTransfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof infoABI,
    'acceptDefaultAdminTransfer'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"addReservedAccount"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoAddReservedAccount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'addReservedAccount'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'addReservedAccount',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'addReservedAccount'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"beginDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoBeginDefaultAdminTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'beginDefaultAdminTransfer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'beginDefaultAdminTransfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof infoABI,
    'beginDefaultAdminTransfer'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"cancelDefaultAdminTransfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoCancelDefaultAdminTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'cancelDefaultAdminTransfer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'cancelDefaultAdminTransfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof infoABI,
    'cancelDefaultAdminTransfer'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"changeDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoChangeDefaultAdminDelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'changeDefaultAdminDelay'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'changeDefaultAdminDelay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'changeDefaultAdminDelay'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"elevate"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoElevate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'elevate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'elevate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'elevate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'grantRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'grantRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"housekeeping"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoHousekeeping(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'housekeeping'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'housekeeping',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'housekeeping'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"pause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"removeReservedAccount"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoRemoveReservedAccount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'removeReservedAccount'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'removeReservedAccount',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'removeReservedAccount'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'renounceRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'renounceRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"requestElevation"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoRequestElevation(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'requestElevation'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'requestElevation',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'requestElevation'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'revokeRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'revokeRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"rollbackDefaultAdminDelay"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoRollbackDefaultAdminDelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'rollbackDefaultAdminDelay'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'rollbackDefaultAdminDelay',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof infoABI,
    'rollbackDefaultAdminDelay'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"setDefaultElevationDuration"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoSetDefaultElevationDuration(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof infoABI,
      'setDefaultElevationDuration'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'setDefaultElevationDuration',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof infoABI,
    'setDefaultElevationDuration'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"setToken"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoSetToken(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'setToken'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'setToken',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'setToken'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"setTreasury"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoSetTreasury(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'setTreasury'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'setTreasury',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'setTreasury'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"unpause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"upgradeTo"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoUpgradeTo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'upgradeTo'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'upgradeTo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'upgradeTo'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link infoABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function usePrepareInfoUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof infoABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof infoABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof infoABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    ...config,
  } as UseContractEventConfig<typeof infoABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"AdminChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'AdminChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'AdminChanged',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'AdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"BeaconUpgraded"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoBeaconUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'BeaconUpgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'BeaconUpgraded',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'BeaconUpgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"DefaultAdminDelayChangeCanceled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdminDelayChangeCanceledEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'DefaultAdminDelayChangeCanceled'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'DefaultAdminDelayChangeCanceled',
    ...config,
  } as UseContractEventConfig<
    typeof infoABI,
    'DefaultAdminDelayChangeCanceled'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"DefaultAdminDelayChangeScheduled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdminDelayChangeScheduledEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'DefaultAdminDelayChangeScheduled'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'DefaultAdminDelayChangeScheduled',
    ...config,
  } as UseContractEventConfig<
    typeof infoABI,
    'DefaultAdminDelayChangeScheduled'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"DefaultAdminTransferCanceled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdminTransferCanceledEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'DefaultAdminTransferCanceled'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'DefaultAdminTransferCanceled',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'DefaultAdminTransferCanceled'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"DefaultAdminTransferScheduled"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoDefaultAdminTransferScheduledEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'DefaultAdminTransferScheduled'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'DefaultAdminTransferScheduled',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'DefaultAdminTransferScheduled'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"Initialized"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"Paused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"RoleAdminChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRoleAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'RoleAdminChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'RoleAdminChanged',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'RoleAdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"RoleGranted"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRoleGrantedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'RoleGranted'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'RoleGranted',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'RoleGranted'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"RoleRevoked"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoRoleRevokedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'RoleRevoked'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'RoleRevoked',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'RoleRevoked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"Unpaused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link infoABI}__ and `eventName` set to `"Upgraded"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x84e91FDD4cfb22d4266f8Be309e8C4E2f9f24fcB)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0x67635527C0a8D872AD6c1b7E9c3d53a771FAe3e5)
 */
export function useInfoUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof infoABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof infoAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: infoABI,
    address: infoAddress[chainId as keyof typeof infoAddress],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof infoABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"CLOCK_MODE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenClockMode<
  TFunctionName extends 'CLOCK_MODE',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'CLOCK_MODE',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"MINTER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenMinterRole<
  TFunctionName extends 'MINTER_ROLE',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'MINTER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"PAUSER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenPauserRole<
  TFunctionName extends 'PAUSER_ROLE',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'PAUSER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"UPGRADER_ROLE"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenUpgraderRole<
  TFunctionName extends 'UPGRADER_ROLE',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'UPGRADER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"allowance"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"balanceOf"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"checkpoints"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenCheckpoints<
  TFunctionName extends 'checkpoints',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'checkpoints',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"clock"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenClock<
  TFunctionName extends 'clock',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'clock',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"decimals"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"delegates"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDelegates<
  TFunctionName extends 'delegates',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'delegates',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"eip712Domain"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenEip712Domain<
  TFunctionName extends 'eip712Domain',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'eip712Domain',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"getPastTotalSupply"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenGetPastTotalSupply<
  TFunctionName extends 'getPastTotalSupply',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'getPastTotalSupply',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"getPastVotes"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenGetPastVotes<
  TFunctionName extends 'getPastVotes',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'getPastVotes',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"getRoleAdmin"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"getVotes"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"hasRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"name"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"nonces"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"numCheckpoints"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenNumCheckpoints<
  TFunctionName extends 'numCheckpoints',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'numCheckpoints',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"paused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"proxiableUUID"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"symbol"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"totalSupply"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof tokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof tokenABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof tokenABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof tokenABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, TFunctionName, TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof tokenABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'approve', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"burn"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenBurn<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof tokenABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'burn' }
    : UseContractWriteConfig<typeof tokenABI, 'burn', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burn'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'burn', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'burn',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"burnFrom"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenBurnFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'burnFrom'
        >['request']['abi'],
        'burnFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'burnFrom' }
    : UseContractWriteConfig<typeof tokenABI, 'burnFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burnFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'burnFrom', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'burnFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'decreaseAllowance'
      }
    : UseContractWriteConfig<typeof tokenABI, 'decreaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'decreaseAllowance'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'decreaseAllowance', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"delegate"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDelegate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'delegate'
        >['request']['abi'],
        'delegate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'delegate' }
    : UseContractWriteConfig<typeof tokenABI, 'delegate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'delegate'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'delegate', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'delegate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"delegateBySig"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDelegateBySig<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'delegateBySig'
        >['request']['abi'],
        'delegateBySig',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'delegateBySig'
      }
    : UseContractWriteConfig<typeof tokenABI, 'delegateBySig', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'delegateBySig'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'delegateBySig', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'delegateBySig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenGrantRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof tokenABI, 'grantRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'grantRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'grantRole', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'grantRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'increaseAllowance'
      }
    : UseContractWriteConfig<typeof tokenABI, 'increaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'increaseAllowance'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'increaseAllowance', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'initialize'
        >['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof tokenABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'initialize', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"mint"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof tokenABI, 'mint'>['request']['abi'],
        'mint',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'mint' }
    : UseContractWriteConfig<typeof tokenABI, 'mint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mint'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'mint', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'mint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"pause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof tokenABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof tokenABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'pause', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"permit"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenPermit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof tokenABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'permit' }
    : UseContractWriteConfig<typeof tokenABI, 'permit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'permit'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'permit', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'permit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenRenounceRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'renounceRole'
      }
    : UseContractWriteConfig<typeof tokenABI, 'renounceRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'renounceRole', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'renounceRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenRevokeRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof tokenABI, 'revokeRole', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'revokeRole'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'revokeRole', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'revokeRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"transfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transfer' }
    : UseContractWriteConfig<typeof tokenABI, 'transfer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transfer'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'transfer', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferFrom'
      }
    : UseContractWriteConfig<typeof tokenABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'transferFrom', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"unpause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'unpause'
        >['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof tokenABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'unpause', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"upgradeTo"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenUpgradeTo<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'upgradeTo'
        >['request']['abi'],
        'upgradeTo',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'upgradeTo' }
    : UseContractWriteConfig<typeof tokenABI, 'upgradeTo', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeTo'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'upgradeTo', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'upgradeTo',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof tokenAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenABI,
          'upgradeToAndCall'
        >['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      }
    : UseContractWriteConfig<typeof tokenABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'upgradeToAndCall'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof tokenABI, 'upgradeToAndCall', TMode>({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"burn"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'burn'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'burn'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"burnFrom"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenBurnFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'burnFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'burnFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'burnFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'decreaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"delegate"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenDelegate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'delegate'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'delegate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'delegate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"delegateBySig"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenDelegateBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'delegateBySig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'delegateBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'delegateBySig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'grantRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'grantRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'increaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"mint"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'mint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'mint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"pause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"permit"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'permit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'permit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'renounceRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'renounceRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'revokeRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'revokeRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"transfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'transfer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"unpause"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"upgradeTo"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenUpgradeTo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'upgradeTo'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'upgradeTo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'upgradeTo'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenABI}__ and `functionName` set to `"upgradeToAndCall"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function usePrepareTokenUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    ...config,
  } as UseContractEventConfig<typeof tokenABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"AdminChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'AdminChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'AdminChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'AdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"Approval"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"BeaconUpgraded"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenBeaconUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'BeaconUpgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'BeaconUpgraded',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'BeaconUpgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"DelegateChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDelegateChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'DelegateChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'DelegateChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'DelegateChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"DelegateVotesChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenDelegateVotesChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'DelegateVotesChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'DelegateVotesChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'DelegateVotesChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"EIP712DomainChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenEip712DomainChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'EIP712DomainChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'EIP712DomainChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'EIP712DomainChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"Initialized"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"Paused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"RoleAdminChanged"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenRoleAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'RoleAdminChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'RoleAdminChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'RoleAdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"RoleGranted"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenRoleGrantedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'RoleGranted'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'RoleGranted',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'RoleGranted'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"RoleRevoked"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenRoleRevokedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'RoleRevoked'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'RoleRevoked',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'RoleRevoked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"Transfer"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"Unpaused"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenABI}__ and `eventName` set to `"Upgraded"`.
 *
 * - [__View Contract on Arbitrum One Arbiscan__](https://arbiscan.io/address/0x3404149e9EE6f17Fb41DB1Ce593ee48FBDcD9506)
 * - [__View Contract on Arbitrum Goerli Arbiscan__](https://goerli.arbiscan.io//address/0xC59DA616B7f3De41D7EA4D02F95abAF7C77b9D0A)
 */
export function useTokenUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof tokenAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractEvent({
    abi: tokenABI,
    address: tokenAddress[chainId as keyof typeof tokenAddress],
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof tokenABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__.
 */
export function useTokenTestnetRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"CLOCK_MODE"`.
 */
export function useTokenTestnetClockMode<
  TFunctionName extends 'CLOCK_MODE',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'CLOCK_MODE',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function useTokenTestnetDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useTokenTestnetDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"MINTER_ROLE"`.
 */
export function useTokenTestnetMinterRole<
  TFunctionName extends 'MINTER_ROLE',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'MINTER_ROLE',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"PAUSER_ROLE"`.
 */
export function useTokenTestnetPauserRole<
  TFunctionName extends 'PAUSER_ROLE',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'PAUSER_ROLE',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"UPGRADER_ROLE"`.
 */
export function useTokenTestnetUpgraderRole<
  TFunctionName extends 'UPGRADER_ROLE',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'UPGRADER_ROLE',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"allowance"`.
 */
export function useTokenTestnetAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useTokenTestnetBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"checkpoints"`.
 */
export function useTokenTestnetCheckpoints<
  TFunctionName extends 'checkpoints',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'checkpoints',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"clock"`.
 */
export function useTokenTestnetClock<
  TFunctionName extends 'clock',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'clock',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"decimals"`.
 */
export function useTokenTestnetDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"delegates"`.
 */
export function useTokenTestnetDelegates<
  TFunctionName extends 'delegates',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'delegates',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"eip712Domain"`.
 */
export function useTokenTestnetEip712Domain<
  TFunctionName extends 'eip712Domain',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'eip712Domain',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"getPastTotalSupply"`.
 */
export function useTokenTestnetGetPastTotalSupply<
  TFunctionName extends 'getPastTotalSupply',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'getPastTotalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"getPastVotes"`.
 */
export function useTokenTestnetGetPastVotes<
  TFunctionName extends 'getPastVotes',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'getPastVotes',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useTokenTestnetGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"getVotes"`.
 */
export function useTokenTestnetGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"hasRole"`.
 */
export function useTokenTestnetHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"name"`.
 */
export function useTokenTestnetName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"nonces"`.
 */
export function useTokenTestnetNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"numCheckpoints"`.
 */
export function useTokenTestnetNumCheckpoints<
  TFunctionName extends 'numCheckpoints',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'numCheckpoints',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"paused"`.
 */
export function useTokenTestnetPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"proxiableUUID"`.
 */
export function useTokenTestnetProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useTokenTestnetSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"symbol"`.
 */
export function useTokenTestnetSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useTokenTestnetTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof tokenTestnetABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTestnetABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTestnetABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof tokenTestnetABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__.
 */
export function useTokenTestnetWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof tokenTestnetABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, TFunctionName, TMode>({
    abi: tokenTestnetABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"approve"`.
 */
export function useTokenTestnetApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'approve', TMode>({
    abi: tokenTestnetABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"burn"`.
 */
export function useTokenTestnetBurn<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'burn'
        >['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'burn', TMode> & {
        abi?: never
        functionName?: 'burn'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'burn', TMode>({
    abi: tokenTestnetABI,
    functionName: 'burn',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"burnFrom"`.
 */
export function useTokenTestnetBurnFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'burnFrom'
        >['request']['abi'],
        'burnFrom',
        TMode
      > & { functionName?: 'burnFrom' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'burnFrom', TMode> & {
        abi?: never
        functionName?: 'burnFrom'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'burnFrom', TMode>({
    abi: tokenTestnetABI,
    functionName: 'burnFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useTokenTestnetDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<
        typeof tokenTestnetABI,
        'decreaseAllowance',
        TMode
      > & {
        abi?: never
        functionName?: 'decreaseAllowance'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'decreaseAllowance', TMode>({
    abi: tokenTestnetABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"delegate"`.
 */
export function useTokenTestnetDelegate<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'delegate'
        >['request']['abi'],
        'delegate',
        TMode
      > & { functionName?: 'delegate' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'delegate', TMode> & {
        abi?: never
        functionName?: 'delegate'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'delegate', TMode>({
    abi: tokenTestnetABI,
    functionName: 'delegate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function useTokenTestnetDelegateBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'delegateBySig'
        >['request']['abi'],
        'delegateBySig',
        TMode
      > & { functionName?: 'delegateBySig' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'delegateBySig', TMode> & {
        abi?: never
        functionName?: 'delegateBySig'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'delegateBySig', TMode>({
    abi: tokenTestnetABI,
    functionName: 'delegateBySig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"grantRole"`.
 */
export function useTokenTestnetGrantRole<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'grantRole', TMode> & {
        abi?: never
        functionName?: 'grantRole'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'grantRole', TMode>({
    abi: tokenTestnetABI,
    functionName: 'grantRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useTokenTestnetIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<
        typeof tokenTestnetABI,
        'increaseAllowance',
        TMode
      > & {
        abi?: never
        functionName?: 'increaseAllowance'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'increaseAllowance', TMode>({
    abi: tokenTestnetABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"initialize"`.
 */
export function useTokenTestnetInitialize<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'initialize'
        >['request']['abi'],
        'initialize',
        TMode
      > & { functionName?: 'initialize' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'initialize', TMode> & {
        abi?: never
        functionName?: 'initialize'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'initialize', TMode>({
    abi: tokenTestnetABI,
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"mint"`.
 */
export function useTokenTestnetMint<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'mint'
        >['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'mint', TMode> & {
        abi?: never
        functionName?: 'mint'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'mint', TMode>({
    abi: tokenTestnetABI,
    functionName: 'mint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"pause"`.
 */
export function useTokenTestnetPause<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'pause'
        >['request']['abi'],
        'pause',
        TMode
      > & { functionName?: 'pause' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'pause', TMode> & {
        abi?: never
        functionName?: 'pause'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'pause', TMode>({
    abi: tokenTestnetABI,
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"permit"`.
 */
export function useTokenTestnetPermit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'permit'
        >['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'permit', TMode> & {
        abi?: never
        functionName?: 'permit'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'permit', TMode>({
    abi: tokenTestnetABI,
    functionName: 'permit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useTokenTestnetRenounceRole<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'renounceRole', TMode> & {
        abi?: never
        functionName?: 'renounceRole'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'renounceRole', TMode>({
    abi: tokenTestnetABI,
    functionName: 'renounceRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useTokenTestnetRevokeRole<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'revokeRole', TMode> & {
        abi?: never
        functionName?: 'revokeRole'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'revokeRole', TMode>({
    abi: tokenTestnetABI,
    functionName: 'revokeRole',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"transfer"`.
 */
export function useTokenTestnetTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'transfer', TMode> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'transfer', TMode>({
    abi: tokenTestnetABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useTokenTestnetTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'transferFrom', TMode> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'transferFrom', TMode>({
    abi: tokenTestnetABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"unpause"`.
 */
export function useTokenTestnetUnpause<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'unpause'
        >['request']['abi'],
        'unpause',
        TMode
      > & { functionName?: 'unpause' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'unpause', TMode> & {
        abi?: never
        functionName?: 'unpause'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'unpause', TMode>({
    abi: tokenTestnetABI,
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"upgradeTo"`.
 */
export function useTokenTestnetUpgradeTo<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'upgradeTo'
        >['request']['abi'],
        'upgradeTo',
        TMode
      > & { functionName?: 'upgradeTo' }
    : UseContractWriteConfig<typeof tokenTestnetABI, 'upgradeTo', TMode> & {
        abi?: never
        functionName?: 'upgradeTo'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'upgradeTo', TMode>({
    abi: tokenTestnetABI,
    functionName: 'upgradeTo',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"upgradeToAndCall"`.
 */
export function useTokenTestnetUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof tokenTestnetABI,
          'upgradeToAndCall'
        >['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<
        typeof tokenTestnetABI,
        'upgradeToAndCall',
        TMode
      > & {
        abi?: never
        functionName?: 'upgradeToAndCall'
      } = {} as any,
) {
  return useContractWrite<typeof tokenTestnetABI, 'upgradeToAndCall', TMode>({
    abi: tokenTestnetABI,
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__.
 */
export function usePrepareTokenTestnetWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareTokenTestnetApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareTokenTestnetBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'burn'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"burnFrom"`.
 */
export function usePrepareTokenTestnetBurnFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'burnFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'burnFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'burnFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareTokenTestnetDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof tokenTestnetABI,
    'decreaseAllowance'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"delegate"`.
 */
export function usePrepareTokenTestnetDelegate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'delegate'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'delegate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'delegate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function usePrepareTokenTestnetDelegateBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'delegateBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'delegateBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'delegateBySig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareTokenTestnetGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'grantRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareTokenTestnetIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof tokenTestnetABI,
    'increaseAllowance'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"initialize"`.
 */
export function usePrepareTokenTestnetInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'initialize'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareTokenTestnetMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'mint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"pause"`.
 */
export function usePrepareTokenTestnetPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'pause'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareTokenTestnetPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'permit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareTokenTestnetRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'renounceRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareTokenTestnetRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'revokeRole'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareTokenTestnetTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareTokenTestnetTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"unpause"`.
 */
export function usePrepareTokenTestnetUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'unpause'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"upgradeTo"`.
 */
export function usePrepareTokenTestnetUpgradeTo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'upgradeTo'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'upgradeTo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'upgradeTo'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTestnetABI}__ and `functionName` set to `"upgradeToAndCall"`.
 */
export function usePrepareTokenTestnetUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTestnetABI, 'upgradeToAndCall'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTestnetABI,
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof tokenTestnetABI,
    'upgradeToAndCall'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__.
 */
export function useTokenTestnetEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, TEventName>,
    'abi'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"AdminChanged"`.
 */
export function useTokenTestnetAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'AdminChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'AdminChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'AdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"Approval"`.
 */
export function useTokenTestnetApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'Approval'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"BeaconUpgraded"`.
 */
export function useTokenTestnetBeaconUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'BeaconUpgraded'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'BeaconUpgraded',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'BeaconUpgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"DelegateChanged"`.
 */
export function useTokenTestnetDelegateChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'DelegateChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'DelegateChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'DelegateChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"DelegateVotesChanged"`.
 */
export function useTokenTestnetDelegateVotesChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'DelegateVotesChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'DelegateVotesChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'DelegateVotesChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"EIP712DomainChanged"`.
 */
export function useTokenTestnetEip712DomainChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'EIP712DomainChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'EIP712DomainChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'EIP712DomainChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"Initialized"`.
 */
export function useTokenTestnetInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'Initialized'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"Paused"`.
 */
export function useTokenTestnetPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'Paused'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"RoleAdminChanged"`.
 */
export function useTokenTestnetRoleAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'RoleAdminChanged'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'RoleAdminChanged',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'RoleAdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"RoleGranted"`.
 */
export function useTokenTestnetRoleGrantedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'RoleGranted'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'RoleGranted',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'RoleGranted'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"RoleRevoked"`.
 */
export function useTokenTestnetRoleRevokedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'RoleRevoked'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'RoleRevoked',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'RoleRevoked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"Transfer"`.
 */
export function useTokenTestnetTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'Transfer'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"Unpaused"`.
 */
export function useTokenTestnetUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'Unpaused'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link tokenTestnetABI}__ and `eventName` set to `"Upgraded"`.
 */
export function useTokenTestnetUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof tokenTestnetABI, 'Upgraded'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: tokenTestnetABI,
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof tokenTestnetABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ierc20ABI, ...config } as UseContractReadConfig<
    typeof ierc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20ABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20BalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20ABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20TotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20ABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc20ABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, TFunctionName, TMode>({
    abi: ierc20ABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20Approve<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20ABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof ierc20ABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, 'approve', TMode>({
    abi: ierc20ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20Transfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20ABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof ierc20ABI, 'transfer', TMode> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, 'transfer', TMode>({
    abi: ierc20ABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20TransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20ABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof ierc20ABI, 'transferFrom', TMode> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, 'transferFrom', TMode>({
    abi: ierc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function usePrepareIerc20Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20Approve(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20Transfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20TransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Event<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof ierc20ABI, TEventName>,
    'abi'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20ABI,
    ...config,
  } as UseContractEventConfig<typeof ierc20ABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20ABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc20ApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20ABI, 'Approval'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20ABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof ierc20ABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc20TransferEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20ABI, 'Transfer'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20ABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof ierc20ABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20UpgradeableABI}__.
 */
export function useIerc20UpgradeableRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20UpgradeableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20UpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20UpgradeableABI,
    ...config,
  } as UseContractReadConfig<
    typeof ierc20UpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20UpgradeableAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof ierc20UpgradeableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20UpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20UpgradeableABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20UpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20UpgradeableBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof ierc20UpgradeableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20UpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20UpgradeableABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20UpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20UpgradeableTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof ierc20UpgradeableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20UpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20UpgradeableABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20UpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__.
 */
export function useIerc20UpgradeableWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20UpgradeableABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<
        typeof ierc20UpgradeableABI,
        TFunctionName,
        TMode
      > & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof ierc20UpgradeableABI, TFunctionName, TMode>({
    abi: ierc20UpgradeableABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20UpgradeableApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20UpgradeableABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof ierc20UpgradeableABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20UpgradeableABI, 'approve', TMode>({
    abi: ierc20UpgradeableABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20UpgradeableTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20UpgradeableABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof ierc20UpgradeableABI, 'transfer', TMode> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20UpgradeableABI, 'transfer', TMode>({
    abi: ierc20UpgradeableABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20UpgradeableTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20UpgradeableABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<
        typeof ierc20UpgradeableABI,
        'transferFrom',
        TMode
      > & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20UpgradeableABI, 'transferFrom', TMode>({
    abi: ierc20UpgradeableABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__.
 */
export function usePrepareIerc20UpgradeableWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20UpgradeableABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20UpgradeableABI,
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20UpgradeableABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20UpgradeableApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20UpgradeableABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20UpgradeableABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20UpgradeableABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20UpgradeableTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20UpgradeableABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20UpgradeableABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20UpgradeableABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20UpgradeableTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20UpgradeableABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20UpgradeableABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20UpgradeableABI,
    'transferFrom'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20UpgradeableABI}__.
 */
export function useIerc20UpgradeableEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof ierc20UpgradeableABI, TEventName>,
    'abi'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20UpgradeableABI,
    ...config,
  } as UseContractEventConfig<typeof ierc20UpgradeableABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc20UpgradeableApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20UpgradeableABI, 'Approval'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20UpgradeableABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof ierc20UpgradeableABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20UpgradeableABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc20UpgradeableTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20UpgradeableABI, 'Transfer'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20UpgradeableABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof ierc20UpgradeableABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20MetadataAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20MetadataBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"decimals"`.
 */
export function useIerc20MetadataDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"name"`.
 */
export function useIerc20MetadataName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"symbol"`.
 */
export function useIerc20MetadataSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20MetadataTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc20MetadataABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, TFunctionName, TMode>({
    abi: ierc20MetadataABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20MetadataApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof ierc20MetadataABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, 'approve', TMode>({
    abi: ierc20MetadataABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20MetadataTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof ierc20MetadataABI, 'transfer', TMode> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, 'transfer', TMode>({
    abi: ierc20MetadataABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20MetadataTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<
        typeof ierc20MetadataABI,
        'transferFrom',
        TMode
      > & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, 'transferFrom', TMode>({
    abi: ierc20MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function usePrepareIerc20MetadataWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20MetadataApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20MetadataTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20MetadataTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof ierc20MetadataABI, TEventName>,
    'abi'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20MetadataABI,
    ...config,
  } as UseContractEventConfig<typeof ierc20MetadataABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc20MetadataApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20MetadataABI, 'Approval'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20MetadataABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof ierc20MetadataABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc20MetadataTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20MetadataABI, 'Transfer'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20MetadataABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof ierc20MetadataABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__.
 */
export function useIerc20MetadataUpgradeableRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataUpgradeableABI,
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20MetadataUpgradeableAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20MetadataUpgradeableBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"decimals"`.
 */
export function useIerc20MetadataUpgradeableDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"name"`.
 */
export function useIerc20MetadataUpgradeableName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"symbol"`.
 */
export function useIerc20MetadataUpgradeableSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20MetadataUpgradeableTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__.
 */
export function useIerc20MetadataUpgradeableWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataUpgradeableABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<
        typeof ierc20MetadataUpgradeableABI,
        TFunctionName,
        TMode
      > & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName,
    TMode
  >({ abi: ierc20MetadataUpgradeableABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20MetadataUpgradeableApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataUpgradeableABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<
        typeof ierc20MetadataUpgradeableABI,
        'approve',
        TMode
      > & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<
    typeof ierc20MetadataUpgradeableABI,
    'approve',
    TMode
  >({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20MetadataUpgradeableTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataUpgradeableABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<
        typeof ierc20MetadataUpgradeableABI,
        'transfer',
        TMode
      > & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any,
) {
  return useContractWrite<
    typeof ierc20MetadataUpgradeableABI,
    'transfer',
    TMode
  >({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20MetadataUpgradeableTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataUpgradeableABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<
        typeof ierc20MetadataUpgradeableABI,
        'transferFrom',
        TMode
      > & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<
    typeof ierc20MetadataUpgradeableABI,
    'transferFrom',
    TMode
  >({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__.
 */
export function usePrepareIerc20MetadataUpgradeableWrite<
  TFunctionName extends string,
>(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof ierc20MetadataUpgradeableABI,
      TFunctionName
    >,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataUpgradeableABI,
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20MetadataUpgradeableABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20MetadataUpgradeableApprove(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof ierc20MetadataUpgradeableABI,
      'approve'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20MetadataUpgradeableABI,
    'approve'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20MetadataUpgradeableTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof ierc20MetadataUpgradeableABI,
      'transfer'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20MetadataUpgradeableABI,
    'transfer'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20MetadataUpgradeableTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof ierc20MetadataUpgradeableABI,
      'transferFrom'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataUpgradeableABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20MetadataUpgradeableABI,
    'transferFrom'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__.
 */
export function useIerc20MetadataUpgradeableEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof ierc20MetadataUpgradeableABI, TEventName>,
    'abi'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20MetadataUpgradeableABI,
    ...config,
  } as UseContractEventConfig<typeof ierc20MetadataUpgradeableABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc20MetadataUpgradeableApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20MetadataUpgradeableABI, 'Approval'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20MetadataUpgradeableABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof ierc20MetadataUpgradeableABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataUpgradeableABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc20MetadataUpgradeableTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof ierc20MetadataUpgradeableABI, 'Transfer'>,
    'abi' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: ierc20MetadataUpgradeableABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof ierc20MetadataUpgradeableABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20PermitUpgradeableABI}__.
 */
export function useIerc20PermitUpgradeableRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20PermitUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20PermitUpgradeableABI,
    ...config,
  } as UseContractReadConfig<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20PermitUpgradeableABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useIerc20PermitUpgradeableDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20PermitUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20PermitUpgradeableABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20PermitUpgradeableABI}__ and `functionName` set to `"nonces"`.
 */
export function useIerc20PermitUpgradeableNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof ierc20PermitUpgradeableABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20PermitUpgradeableABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20PermitUpgradeableABI}__.
 */
export function useIerc20PermitUpgradeableWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20PermitUpgradeableABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<
        typeof ierc20PermitUpgradeableABI,
        TFunctionName,
        TMode
      > & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName,
    TMode
  >({ abi: ierc20PermitUpgradeableABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20PermitUpgradeableABI}__ and `functionName` set to `"permit"`.
 */
export function useIerc20PermitUpgradeablePermit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20PermitUpgradeableABI,
          'permit'
        >['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<
        typeof ierc20PermitUpgradeableABI,
        'permit',
        TMode
      > & {
        abi?: never
        functionName?: 'permit'
      } = {} as any,
) {
  return useContractWrite<typeof ierc20PermitUpgradeableABI, 'permit', TMode>({
    abi: ierc20PermitUpgradeableABI,
    functionName: 'permit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20PermitUpgradeableABI}__.
 */
export function usePrepareIerc20PermitUpgradeableWrite<
  TFunctionName extends string,
>(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof ierc20PermitUpgradeableABI,
      TFunctionName
    >,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20PermitUpgradeableABI,
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20PermitUpgradeableABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20PermitUpgradeableABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareIerc20PermitUpgradeablePermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20PermitUpgradeableABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20PermitUpgradeableABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc20PermitUpgradeableABI,
    'permit'
  >)
}
