import { useState } from "react"
import { NavLink } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { ConnectKitButton } from "connectkit"

import BackgroundWorker from "./BackgroundWorker"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Navbar from "react-bootstrap/Navbar"
import NavItem from "react-bootstrap/NavItem"
import Offcanvas from 'react-bootstrap/Offcanvas'

import logo from "../assets/logo.svg"

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 1180 })
  return isDesktop ? children : null
}

const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 1179 })
  return isMobile ? children : null
}

export default function Header() {
  const [showMenu, setShowMenu] = useState(false)



  return (
    <>
      <header>
        <Container>
          <Row className="align-items-center">
            <Col lg={3} className="col-5">
              <NavLink className="logo" to="/">
                <img src={logo} alt="logo"></img>
              </NavLink>
            </Col>
            <Col lg={9} className="col-7">
              <Desktop>
                <div className="d-flex justify-content-end align-items-center">
                  <Navbar className="main-nav">
                    <NavItem>
                      <NavLink to="/">BONDS</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="mybonds">MY BONDS</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="faqs">FAQs</NavLink>
                    </NavItem>
                  </Navbar>
                  <ConnectKitButton.Custom>
                    {({ isConnected, isConnecting, show, hide, truncatedAddress, ensName, chain }) => {
                      return (
                        <Button onClick={show} className="button-primary ms-3">
                          <p>{isConnected ? ensName ?? truncatedAddress : "CONNECT"}</p>
                          {isConnected && chain?.testnet && (<p>{chain?.name} (Testnet)</p>)}
                        </Button>
                      )
                    }}
                  </ConnectKitButton.Custom>
                </div>
              </Desktop>

              <Mobile>
                <div className="d-flex justify-content-end">
                  <Button className="menu-control" onClick={() => setShowMenu(!showMenu)} aria-label="menu">
                  </Button>
                </div>
              </Mobile>
            </Col>
          </Row>
        </Container>

        <Offcanvas id="offcanvasMenu" show={showMenu} onHide={() => setShowMenu(false)} placement="start" scroll={true} backdrop={false} className="offcanvas-menu" aria-labelledby="offcanvasMenuLabel">
          <Offcanvas.Header>
            <Offcanvas.Title id="offcanvasMenuLabel">
              {/* <h5>Menu</h5> */}
              Menu
            </Offcanvas.Title>
            <Button className="close-button" onClick={() => setShowMenu(!showMenu)} aria-label="Close">
              {/* // TODO: x centered in cicle */}
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Navbar className="mobile-nav">
            <NavItem  onClick={() => setShowMenu(!showMenu)} >
                <NavLink to="/">BONDS</NavLink>
              </NavItem>
              <NavItem  onClick={() => setShowMenu(!showMenu)} >
                <NavLink to="mybonds">MY BONDS</NavLink>
              </NavItem>
              <NavItem  onClick={() => setShowMenu(!showMenu)} >
                <NavLink to="faqs">FAQs</NavLink>
              </NavItem>
            </Navbar>
            <div className="d-flex justify-content-center align-items-center">
              <ConnectKitButton.Custom>
                {({ isConnected, isConnecting, show, hide, truncatedAddress, ensName, chain }) => {
                  return (
                    <Button onClick={show} className="button-primary mt-4 mx-auto">
                      <p>{isConnected ? ensName ?? truncatedAddress : "CONNECT WALLET"}</p>
                      {isConnected && chain?.testnet && (<p>{chain?.name} (Testnet)</p>)}
                    </Button>
                  )
                }}
              </ConnectKitButton.Custom>
            </div>
            {/* <Button as="a" href="https://hydranet.ai" className="button-secondary w-100 d-block">
              BACK HOME
            </Button> */}
          </Offcanvas.Body>
        </Offcanvas>
      </header>
      <BackgroundWorker />
    </>
  )
}
