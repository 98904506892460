import { API_URL_ROOT } from "./url"
import { App } from "../../../constants"
import { ApiResponse } from "./interface"


// Fish is a wrapper for fetch containing common headers and error handling
export const fish = {
    get: <T>(path: string) => fisher<T>(path),
    post: <T>(path: string, body?: any) => fisher<T>(path, "POST", body)
}

async function fisher<T>(path: string, method: string = "GET", body?: any): Promise<T> {
    const req = newFishReq(`${API_URL_ROOT}${path}`, method, body)
    req.headers.set("Content-Type", "application/json")

    const res = await (await fetch(req)).json<ApiResponse<T>>()
    if (res.status.http_status !== 200) {
        throw new Error(`API [HTTP ${res.status.http_status}] Error ${res.status.error?.code}: ${res.status.error?.message}`)
    }

    return res.data as T
}

function newFishReq(url: string, method: string = "GET", body?: any) {
    let reqBody = undefined
    if (body) {
        reqBody = JSON.stringify(body)
        if (reqBody === undefined) {
            throw new Error(`Failed to serialize body: ${body}`)
        }
    }
    return new Request(url.toLowerCase(), {
        headers: HEADERS,
        method: method,
        body: reqBody,
    })
}

const HEADERS = {
    "HN-App-ID": getAppId(),
    "HN-ClientPlatform-ID": "Browser",
    "X-API-Key": "no_need_for_now",
}

function getAppId(): string {
    const env = process.env.REACT_APP_STAGE
    const clientId = `${App.ClientId}_${env}`
    return clientId.replaceAll("-", "_").replaceAll(" ", "_").replaceAll(".", "_").replaceAll(":", "_").replaceAll(";", "_")
}

