import { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../store/hook"
import { App } from "../constants"
import { convertFromToken, convertToToken } from "../utils/convert"
import { formatTokenAmount, formatFiatCurrency, formatDuration } from "../utils/format"
import { trace, debug } from "../utils/trace"
import { bondMarketDetails, bond, transformBond } from "../shared/interfaces/bonds"
import { updateBalance, updateAllowance } from "../store/slices/accountSlice"
import { addMyBond } from "../store/slices/bondsSlice"
import { tokenDigitsFromAddress } from "../utils/token"


import {
    useIerc20BalanceOf, useIerc20TransferEvent,
    useIerc20Allowance, useIerc20Approve, usePrepareIerc20Approve, useIerc20ApprovalEvent,
    bondageAddress, useBondageBuyBond, usePrepareBondageBuyBond, useBondageBondSoldEvent
} from "../web3/glue"

import { toast } from "react-toastify"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"

import { ErrorBoundary } from "../shared/tools/stry"
import FallbackGenericError from "./FallbackGenericError"




export default function BuyBondModal({ bondMarket, show = false, onClose }: { bondMarket: bondMarketDetails, show: boolean, onClose?: () => void }) {
    const dispatch = useAppDispatch()
    const { chainId, account } = useAppSelector(state => state.account)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [step, setSteps] = useState(0.00001)                          // steps for input - 
    const [input, setInput] = useState(undefined as undefined | number) // input in quote currency (e.g. USDC)
    const [amount, setAmount] = useState(0)         // Amount in Token (HDN)
    const [maxSpend, setMaxSpend] = useState(0)     // maxSpend in quote currency
    const [balance, setBalance] = useState(-1)
    const [allowance, setAllowance] = useState(-1)
    const [buyBondValidation, setBuyBondValidation] = useState(true)
    const [approveValidation, setApproveValidation] = useState(true)
    const [expectingApproval, setExpectingApproval] = useState(0)
    
    // Balance

    const getBalance = useIerc20BalanceOf({
        address: bondMarket.quoteTokenAddress as `0x${string}`,
        args: [
            account.address as `0x${string}`,
        ],
        onSuccess(data) {
            setBalance(convertToToken(data, tokenDigitsFromAddress(bondMarket.quoteTokenAddress)))
        },
    })

    const offBalance = useIerc20TransferEvent({
        address: bondMarket.quoteTokenAddress as `0x${string}`,
        listener: async (data) => {
            const ownerAddr = account.address as `0x${string}`
            let bal = balance
            data.forEach((event: any) => {
                if (event.args.from === ownerAddr) {
                    bal -= convertToToken(event.args.amount, tokenDigitsFromAddress(bondMarket.quoteTokenAddress))
                    setBalance(bal)
                }
                if (event.args.to === ownerAddr) {
                    bal += convertToToken(event.args.amount, tokenDigitsFromAddress(bondMarket.quoteTokenAddress))
                    setBalance(bal)
                }
            })
        }
    })

    // Allowance

    const getAllowance = useIerc20Allowance({
        address: bondMarket.quoteTokenAddress as `0x${string}`,
        args: [
            account.address as `0x${string}`,
            bondageAddress[chainId as keyof typeof bondageAddress],
        ],
        onSuccess(data) {
            setAllowance(convertToToken(data, tokenDigitsFromAddress(bondMarket.quoteTokenAddress)))
            buyBond.reset()
        },
        onError(error) {
            trace("getAllowanceError", "BuyBondModal", error)
        },
    })

    const offAllowance = useIerc20ApprovalEvent({
        address: bondMarket.quoteTokenAddress as `0x${string}`,
        listener: async (data) => {
            const bondageAddr = bondageAddress[chainId as keyof typeof bondageAddress]
            const ownerAddr = account.address as `0x${string}`
            const myNewAllowances = [] as number[]
            data.forEach((event: any) => {
                if (event.args.owner === ownerAddr && event.args.spender === bondageAddr) {
                    myNewAllowances.push(convertToToken(event.args.value, tokenDigitsFromAddress(bondMarket.quoteTokenAddress)))
                }
                if (myNewAllowances.length === 0) {
                    debug("onApproval(NOT_MINE)", "BuyBondModal", data)
                    return
                }
                for (const allowance of myNewAllowances) {
                    setAllowance(allowance)
                }
                buyBond.reset()
                debug("onApproval", "BuyBondModal", myNewAllowances)
                if (expectingApproval > 0) {
                    // Prevent pre-signed approvals from triggering notifications
                    setExpectingApproval(expectingApproval - 1)
                    toast.success("Approved! You are now ready to proceed.")
                }
            })
        }
    })

    const { config: approveConfig } = usePrepareIerc20Approve({
        address: bondMarket.quoteTokenAddress as `0x${string}`,
        args: [
            bondageAddress[chainId as keyof typeof bondageAddress],
            BigInt(convertFromToken(input || maxSpend, tokenDigitsFromAddress(bondMarket.quoteTokenAddress)))
        ],
        onSuccess() {
            setApproveValidation(true)
        },
        onError(error) {
            trace("AllowanceApproveValidationFailed", "BuyBondModal", error)
            setApproveValidation(false)
        },
    })

    const approveAllowance = useIerc20Approve({
        ...approveConfig,
        onError(error) {
            trace("AllowanceApproveError", "BuyBondModal", error)
            toast.error(`Approval failed`)
        },
    })

    // Buy Bond

    const offBondSold = useBondageBondSoldEvent({
        listener: async (data) => {
            const myNewBonds = [] as bond[]
            data.forEach((event) => {
                if (event.args.owner === account.address as `0x${string}` && event.args.bond) {
                    myNewBonds.push(transformBond(event.args.bond))
                }
            })
            if (myNewBonds.length === 0) {
                debug("onBondSold(NOT_MINE)", "BuyBondModal", data)
                return
            }
            for (const newBond of myNewBonds) {
                dispatch(
                    addMyBond({ newBond, chainId })
                )
            }
            // setShowBondToast(true)
            debug("onBondSold", "BuyBondModal", myNewBonds)
            toast.success("Congrats! You bought a bond!")
        }
    })


    const { config: buyBondConfig } = usePrepareBondageBuyBond({
        args: [
            BigInt(bondMarket.id), // marketId
            BigInt(convertFromToken(amount, App.TokenDecimals)), // amount
        ],
        onSuccess() {
            setBuyBondValidation(true)
        },
        onError(error) {
            setBuyBondValidation(false)
            trace("BuyBondValidationFailed", "BuyBondModal", error)
        }
    })

    const buyBond = useBondageBuyBond({
        ...buyBondConfig,
        onSuccess() {
            toast.success("Bond sale in progress!")
            closeModal()
        },
        onError(error) {
            trace("BuyBondError", "BuyBondModal", error)
            toast.error(`Bond sale failed`)
        }
    })

    // State

    useEffect(() => {
        refreshMax()
        refreshAmount()
        setInput(0)
        dispatch(
            updateAllowance({ token: bondMarket.quoteTokenAddress, allowance })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowance])


    useEffect(() => {
        refreshMax()
        dispatch(
            updateBalance({ token: bondMarket.quoteTokenAddress, balance })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balance])

    useEffect(() => {
        if (!!input && input > maxSpend) {
            setInput(maxSpend)
            return // Changing input will call refreshAmount down the tree
        }
        refreshAmount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxSpend])

    useEffect(() => {
        refreshAmount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input])

    // Event Handlers

    const inputChanged = (e?: any) => {
        let val: number | undefined = parseFloat(e?.target?.value);
    
        if (isNaN(val)) {
            setInput(undefined);
            return;
        }
    

        if (val > maxSpend) {
            setInput(maxSpend);
            return;
        }
    
        if (val < 0) {
            setInput(0);
            return;
        }
    
        if (val !== input) {
            setInput(val);
        }
    }

    const closeModal = (e?: any) => {
        show = false
        setInput(undefined)
        setAmount(0)
        offBalance?.()
        offAllowance?.()
        offBondSold?.()
        onClose?.()
    }

    // HELPER //

    const isLoading = () => ((getBalance.isLoading || getBalance.isFetching || (!getBalance.isError && !getBalance.isSuccess))) &&
        ((getAllowance.isLoading || getAllowance.isFetching || (!getAllowance.isError && !getAllowance.isSuccess)))

    const needAllowance = () => allowance === 0 || (input && allowance < input)

    const maxTokensAvailable = () => Math.min(bondMarket.volume - bondMarket.sold, balance / bondMarket.priceQuoteToken)

    const refreshAmount = () => {
        if (!input || input > maxSpend) {
            setAmount(0) // Workaround to avoid failing tx validation
            return
        }
        if (input / bondMarket.priceQuoteToken !== amount) {
            setAmount(input / bondMarket.priceQuoteToken)
        }
    }

    const refreshMax = () => {
        const bmBal = bondMarket?.volume - bondMarket?.sold
        if (!bmBal) {
            if (maxSpend !== 0) {
                setMaxSpend(0) // Pointless to do anything else here if we don't know market volume
            }
            return
        }
        const bmMax = bmBal * bondMarket.priceQuoteToken
        if (balance <= 0) {
            setMaxSpend(0) // Same
            return
        }
        const newMax = Math.min(bmMax, balance)
        if (maxSpend !== newMax) {
            setMaxSpend(Number(newMax.toFixed(5)))
        }
    }


    return (
        <>

            <Modal backdrop="static" size="lg" centered show={show} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title className="fs-5">
                        <i className="fa-solid fa-arrow-right-to-bracket"></i> BUY BOND
                    </Modal.Title>
                    <Button onClick={closeModal} type="button" variant="" className="close-modal"><i className="fa-regular fa-circle-xmark"></i></Button>
                </Modal.Header>

                {isLoading() && (
                    <Modal.Body>
                        <Row className="justify-content-center align-items-center">
                            <Spinner animation="border" variant="primary" style={{ width: "8rem", height: "8rem" }} />
                        </Row>
                    </Modal.Body>
                )}

                {!isLoading() && balance > -1 && allowance > -1 && (

                    <Modal.Body>
                        <ErrorBoundary fallback={<FallbackGenericError></FallbackGenericError>}>
                            <Row className="g-0">
                                <Col className="col-6">
                                    <div className="box-title text-start">
                                        <h5>Bond Price</h5>
                                        <p>{formatTokenAmount(bondMarket.priceQuoteToken)} {bondMarket.quoteTokenTicker} {bondMarket.priceUSD ? `($${formatFiatCurrency(bondMarket.priceUSD)})` : ''} per {bondMarket.refToken}</p>
                                        <div className="bond-asset"><img src={bondMarket.quoteTokenIconSvg} alt={bondMarket.quoteTokenTicker}></img> </div>
                                    </div>
                                </Col>
                                <Col className="col-6">
                                    <div className="box-title text-end">
                                        <h5>Fixed Term</h5>
                                        <p>{formatDuration(bondMarket.term)}</p>
                                    </div>
                                </Col>
                            </Row>

                            {(allowance === 0) && (
                                <div className="info-alert">
                                    First time bonding {bondMarket.quoteTokenTicker}?
                                    Please approve Hydranet to use your <b>{bondMarket.quoteTokenTicker}</b> for bonding.
                                </div>
                            )}
                            {allowance > 0 && (
                                <Form.Floating className="mt-4 mb-4">
                                    {/* TODO: Make MAX button be vertically justified to inputAssetName */}
                                    {/* TODO: Make step maybe back again to a state variable to possibly get steps from token price */}
                                    <Form.Control id="quoteAmountInput" type="number" min={0} max={maxSpend} step={step} value={input} onChange={inputChanged} placeholder={bondMarket.quoteTokenTicker + " Amount"}></Form.Control>
                                    <Form.Label>{bondMarket.quoteTokenTicker + " Amount"}</Form.Label>
                                    <span className="input-asset-name">{bondMarket.quoteTokenTicker}</span>
                                    <Button onClick={e => setInput(maxSpend)} className="input-button">MAX</Button>
                                </Form.Floating>
                            )}
                            {needAllowance() ? (
                                <Button className="block-button" disabled={!approveValidation || !approveAllowance.write || approveAllowance.isLoading || approveAllowance.isSuccess} onClick={(e) => {
                                    setExpectingApproval(expectingApproval + 1)
                                    approveAllowance.write?.()
                                }}>
                                    {!approveAllowance.isLoading && !approveAllowance.isSuccess && (
                                        <>APPROVE</>
                                    )}
                                    {(approveAllowance.isLoading || approveAllowance.isSuccess) && (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />  Acquiring allowance...
                                        </>
                                    )}
                                </Button>
                            ) : null}
                            {!needAllowance() ? (
                                <Button className="block-button" disabled={!input || input <= 0 || !buyBondValidation || !buyBond.write || buyBond.isLoading || buyBond.isSuccess} onClick={(e) => {
                                    buyBond.write?.()
                                }}>
                                    {!buyBond.isLoading && !buyBond.isSuccess && (
                                        <>BUY BOND</>
                                    )}
                                    {(buyBond.isLoading || buyBond.isSuccess) && (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />  Buying Bond...
                                        </>
                                    )}
                                </Button>
                            ) : null}

                            <ul className="bond-details">
                                <li>
                                    <span>Your Balance:</span>
                                    <span>{formatTokenAmount(balance)} {bondMarket.quoteTokenTicker}</span>
                                </li>
                                <li>
                                    <span>You Will Get:</span>
                                    <span>{formatTokenAmount((input ?? 0) / bondMarket.priceQuoteToken)} {bondMarket.refToken}</span>
                                </li>
                                <li>
                                    <span>Max You Can Buy:</span>
                                    <span>{formatTokenAmount(maxTokensAvailable())} {bondMarket.refToken}</span>
                                </li>
                                <li>
                                    <span>Term:</span>
                                    <span>{formatDuration(bondMarket.term)}</span>
                                </li>
                            </ul>
                        </ErrorBoundary>
                    </Modal.Body>
                )}

                {(getBalance.isError || getAllowance.isError) && (
                    <section className="section section-warning mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="warning-connection no-bonds">
                                        <i className="fa-solid fa-circle-exclamation"></i>
                                        <h3>Error</h3>
                                        <p>Something went wrong. That's all we know for now. Try refreshing?</p>
                                        <Button onClick={closeModal} className="ms-3">CLOSE</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Modal>

        </>
    )
}
