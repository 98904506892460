export const App = {
    Name: "Hydranet",
    Description: "DApp for interacting with Hydranet's HDN Token Contracts",
    Url: "https://hydranet.ai",
    ClientId: "hn_bonds_1",
    TokenTicker: "HDN",
    TokenDecimals: 18,
}

export const HexValues = {
    INFINITE_ALLOWANCE: "0xfe00000000000000000000000000000000000000000000000000000000000000"
}

export const Chains = {
    Arbitrum: {
        One: 42161,
        Nova: 42170,
        Goerli: 421613,
    },
}

export const Tokens = {
    WETH: new Map<number, string>([
        [Chains.Arbitrum.One, "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"],
        [Chains.Arbitrum.Goerli,  "0x9402e61033c7CA83269b237EA288bD57135d2D77"] // (Legacy) TwistedWETH9 
    ]),
    DAI: new Map<number, string>([
        [Chains.Arbitrum.One, "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1"],
    ]),
    USDC: new Map<number, string>([
        [Chains.Arbitrum.One, "0xaf88d065e77c8cC2239327C5EDb3A432268e5831"],
    ]),
    USDT: new Map<number, string>([
        [Chains.Arbitrum.One, "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9"],
    ]),
    ARB: new Map<number, string>([
        [Chains.Arbitrum.One, "0x912CE59144191C1204E64559FE8253a0e49E6548"],
    ]),    
    WETH_L: new Map<number, string>([
        [Chains.Arbitrum.Goerli,  "0xFd3Aa40aF20c80a106B0b80E18D93440f48a0fe4"] // (Legacy) TwistedWETH9 
    ])
}

export const Contracts = {
    WETH9: new Map<number,string>([
        [Chains.Arbitrum.One, "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"],
        [Chains.Arbitrum.Goerli, "0x9402e61033c7CA83269b237EA288bD57135d2D77"] // TwistedWETH
    ]),
    WETH9_L: new Map<number,string>([
        [Chains.Arbitrum.Goerli, "0xFd3Aa40aF20c80a106B0b80E18D93440f48a0fe4"] // (Legacy) TwistedWETH9 
    ])
}