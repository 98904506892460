import { ReactNode } from "react";

export default function InfoTableSection({ title, children }: { title: string, children:ReactNode }) {  return (

            <div className="warning-connection no-bonds">
              <i className="fa-solid fa-circle-exclamation"></i>
              <h3>{title}</h3>
                {children}
            </div>

  );
}
