import { useState, useEffect, useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../store/hook"
import { App } from "../constants"
import { nowTimestampSeconds } from "../utils/time"
import { convertToToken } from "../utils/convert"
import { formatLotsOfTokens } from "../utils/format"
import { trace, debug } from "../utils/trace"
import { bond, transformBond, transformMyBonds } from "../shared/interfaces/bonds"
import { processIncomingTransfer, updateBalances } from "../store/slices/accountSlice"
import { removeClaimedBond, updateMyBonds } from "../store/slices/bondsSlice"

import { useChainId, useAccount } from "wagmi"
import {
    useBondageMaturingBonds, useBondageBondClaimedEvent, useTokenBalanceOf, useTokenTransferEvent
} from "../web3/glue"

import { ToastContainer, toast } from 'react-toastify'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import MyBondRow from "../components/MyBondRow"
import MyBondClaimModal from "../components/MyBondClaimModal"
import Web3WalletNotConnected from "../components/Web3WalletNotConnected"
import InfoTableSection from "../components/InfoTableSection"


export default function MyBonds() {
    const dispatch = useAppDispatch()
    const chainId = useChainId()
    const account = useAccount()
    const myBonds = useAppSelector(state => state.bonds.myBonds)
    const [selectedBond, setSelectedBond] = useState(undefined as bond | undefined)
    const [tokenBalance, setTokenBalance] = useState(0)
    const [pendingBondsBalance, setPendingBondsBalance] = useState(0)
    const [claimableBondsBalance, setClaimableBondsBalance] = useState(0)
    const [needRefresh, setNeedRefresh] = useState(false)

    const myBondsContract = useBondageMaturingBonds({
        args: [account.address as `0x${string}`],
        onSuccess: (data) => {
            const bonds = transformMyBonds(data)
            const now = nowTimestampSeconds()
            setPendingBondsBalance(prevPendingBalance =>
                bonds.reduce((acc, bond) => (bond.maturation >= now ? acc + bond.amount : acc), prevPendingBalance)
            );
            setClaimableBondsBalance(prevClaimableBalance =>
                bonds.reduce((acc, bond) => (bond.maturation < now ? acc + bond.amount : acc), prevClaimableBalance)
            );
            dispatch(
                updateMyBonds({
                    update: bonds,
                    chainId: chainId,
                })
            )
        },
        onError: (error) => {
            if (!account.isConnected) {
                return
            }
            trace("getMyBondsError", "mybonds", error)
            toast(`Error fetching your bonds: ${error.message}`)
            // TODO: Proper error handling
        },
    })

    useTokenBalanceOf({
        args: [account.address as `0x${string}`],
        onSuccess: (data) => {
            const newBalance = convertToToken(data, 18)
            setTokenBalance(newBalance)
            dispatch(
                updateBalances({
                    balances: {
                        [App.TokenTicker]: newBalance
                    }
                })
            )
        },
        onError: (error) => {
            if (!account.isConnected) {
                return
            }
            trace("getBalanceError", "MyBondClaimModal", error)
        },
    })


    
    const offBondClaimed = useBondageBondClaimedEvent({
        listener: async (data) => {
            const myClaimedBonds = [] as bond[]
            data.forEach((event) => {
                if (event.args.owner === account.address as `0x${string}` && event.args.bond) {
                    myClaimedBonds.push(transformBond(event.args.bond))
                }
            })
            if (myClaimedBonds.length === 0) {
                debug("onBondClaimed[NOT_MINE]", "MyBondClaimModal", data)
                return
            }
            for (const claimedBond of myClaimedBonds) {
                trace("newClaimedBond", "MyBonds", claimedBond)
                dispatch(
                    removeClaimedBond({ claimedBond })
                )
            }
        }
    })

    const offIncomingTransfer = useTokenTransferEvent({
        listener: async (data) => {
            data.forEach((event) => {
                if (event.args.to === account.address as `0x${string}` && event.args.value) {
                    debug("newIncomingTransfer", "MyBonds", event)
                    const amount = convertToToken(event.args.value, 18)
                    setTokenBalance(prevBalance => prevBalance + amount);
                    setClaimableBondsBalance(prevBalance => prevBalance - amount);
                    dispatch(
                        processIncomingTransfer({
                            token: App.TokenTicker,
                            amount: amount,
                        })
                    )
                }
            })
        },
    })

    // Cleanup
    useEffect(() => {
        // Anything in here is fired on component mount.
        return () => {
            // Anything in here is fired on component unmount.
            offBondClaimed?.()
            offIncomingTransfer?.()
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

        const bondsRows = useMemo(() => {
            if(needRefresh){
                setNeedRefresh(false)
                window.location.reload()
            }else {
                return myBonds.map((bond, ix) => (
                    <MyBondRow key={ix} connected={account.isConnected} bond={bond} setSelectedBond={setSelectedBond}></MyBondRow>
    
                ))
            }
        }, [myBonds, account.isConnected, needRefresh]);


    // Helper
    const myBondsIsLoading = () => (!myBonds || myBonds.length === 0) && (myBondsContract.isLoading || myBondsContract.isFetching) && !myBondsContract.isError

    return (
        <>

            {account.isConnected && (
                <>

                    <section className="section section-filter pt-4">
                        <Container>
                            <Row className="justify-content-between align-items-center">
                                <div className="col-12 mb-4">
                                    <h3 className="title large-title"><i className="fa-solid fa-money-bill-trend-up"></i> <span>My Bonds</span></h3></div>
                                <Col lg="auto" className="col-12 col-md-auto col-lg-auto balance-left">
                                    <ul className="d-flex justify-content-start">
                                        <li className="pe-lg-3">

                                            <span><i className="fa-solid fa-coins green-100 me-1"></i>Balance:</span>
                                            <span className="ms-2">{formatLotsOfTokens(tokenBalance)} {App.TokenTicker}</span>
                                        </li>
                                        <li>

                                            <span><i className="fa-solid fa-stopwatch green-100 me-1"></i>Pending Payout:</span>
                                            <span className="ms-2">{formatLotsOfTokens(pendingBondsBalance)} {App.TokenTicker}</span>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg="auto" className="col-12 col-md-auto col-lg-auto balance-column">
                                    <ul>
                                        <li>
                                            <span><i className="fa-solid fa-check-to-slot green-100 me-1"></i>Claimable:</span>
                                            <span className="ms-2">{formatLotsOfTokens(claimableBondsBalance)} {App.TokenTicker}</span>
                                        </li>
                                    </ul>

                                    <Button disabled className="button-primary color-green claim-all-button ms-lg-3 d-none">CLAIM ALL</Button>

                                </Col>

                            </Row>
                        </Container>
                    </section>
                    <section className="section section-filter-bonds mt-lg-3 mt-5">
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <div className="bond-list my-bonds">
                                        <ul className="bond-list-header">
                                            <li>TYPE</li>
                                            <li>TERM</li>
                                            <li>REMAINING</li>
                                            <li>PENDING PAYOUT</li>
                                            <li>CLAIM</li>
                                        </ul>

                                        <div className="bond-list-body">
                                            {myBondsIsLoading() && (
                                                <Row className="justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="primary" style={{ width: "8rem", height: "8rem" }} />
                                                </Row>
                                            )}
                                            {bondsRows}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {account.isConnected && selectedBond && (
                        <MyBondClaimModal bond={selectedBond} show={!!selectedBond} onClose={() => setNeedRefresh(true)}></MyBondClaimModal>
                    )}

                </>
            )}

            <section className="section section-warning mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                             {account.isConnected && !myBondsIsLoading() && myBonds.length === 0 && (
                                 <InfoTableSection title="Nothing here yet">
                                     <p>Take a look at the latest DEX release or check out the Bonds page.</p>
                                     <NavLink className="button button-primary mt-3 d-inline-block" to="/">VIEW BONDS</NavLink>
                                 </InfoTableSection>
                             )}
                        </div>
                    </div>
                </div>
            </section>

            <Web3WalletNotConnected show={!myBondsIsLoading()}>
                <p className="warning-message">Click the CONNECT button in the top right corner to see your bonds.</p>
            </Web3WalletNotConnected>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

        </>

    )
}
