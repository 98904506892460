import { formatDuration, formatDigits, formatTokenAmount } from "../utils/format";
import { bondMarketDetails } from "../shared/interfaces/bonds";

import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom";

export default function BondMarketRow({
  connected,
  bondMarket,
  setSelectedBondMarket,
  soldOut = false,
}: {
  connected: boolean;
  bondMarket: bondMarketDetails;
  setSelectedBondMarket: (bmd: bondMarketDetails) => void;
  soldOut?: boolean;
}) {
  return (
    <ul>
      <li>
        <div className="bond-type">
          <div className="asset-image">
            {" "}
            <img
              src={bondMarket.quoteTokenIconSvg}
              alt={bondMarket.quoteTokenTicker}
            ></img>
          </div>
          <div className="asset-ticker">
            <p>{bondMarket.quoteTokenTicker}</p>
            <small><Link target="_blank" rel="noreferrer" to={`https://arbiscan.io/address/${bondMarket.quoteTokenAddress}`}>View Token-Contract</Link></small>
          </div>
        </div>
      </li>
      <li>
        <span className="xs-title">Price:</span>
        <span>
          {bondMarket.priceUSD
            ? `$${formatDigits(bondMarket.priceUSD, 3)} / `
            : ""}
          {formatDigits(bondMarket.priceQuoteToken, 6)}{" "}
          {bondMarket.quoteTokenTicker}
        </span>
      </li>
      <li>
         <span className="xs-title">Total / Available:</span>
         <span>{formatTokenAmount(bondMarket.volume)} / {!soldOut ? formatTokenAmount(bondMarket.volume - bondMarket.sold) : '-'}</span>
       </li>
      <li>
        <span className="xs-title">Term:</span>
        <span>{formatDuration(bondMarket.term)}</span>
      </li>
      <li>
        <Button
          disabled={!connected || soldOut}
          onClick={(e) => setSelectedBondMarket(bondMarket)}
          className="button-primary color-green"
        >
          {soldOut ? "SOLD OUT" : connected ? "BUY NOW" : "CONNECT"}
        </Button>
      </li>
    </ul>
  );
}
