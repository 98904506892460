import { configureChains, createConfig } from "wagmi"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { arbitrum, arbitrumGoerli } from '@wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { hydranetProvider } from "./providers/hydranet"
import { getDefaultConfig } from "connectkit"
import { App } from "../constants"

var networksMainnet = [ arbitrum ]
var networksTestnet = [ arbitrumGoerli ]
var networks = process.env.REACT_APP_STAGE === "prod" ? networksMainnet : networksTestnet

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [...networks], 
  [hydranetProvider(), publicProvider()]
)

export const Web3Config = createConfig(
  getDefaultConfig({
      chains,
      publicClient,
      webSocketPublicClient,
      enableWebSocketPublicClient: true,
      appName: App.Name,
      appDescription: App.Description,
      appUrl: App.Url,
      walletConnectProjectId: "",
  }),
)
