import type { Chain } from "@wagmi/chains"
import type { ChainProviderFn } from "wagmi"
import { API_URL_ROOT } from "../../shared/api/hydranet/url"


export function hydranetProvider<TChain extends Chain = Chain>(): ChainProviderFn<TChain> {
    return function (chain) {
        const httpUrl = httpApiUrl(chain)
        const wsUrl = wsApiUrl(chain)
        if (!httpUrl) return null
        return {
            chain: {
                ...chain,
                rpcUrls: {
                    ...chain.rpcUrls,
                    default: { http: [httpUrl] },
                },
            } as TChain,
            rpcUrls: {
                http: [httpUrl],
                webSocket: [wsUrl],
            },
        }
    }
}

function httpApiUrl(chain: Chain) {
    return `${API_URL_ROOT}/eth/${chain.network}`
}

function wsApiUrl(chain: Chain) {
    return httpApiUrl(chain).replace("http", "ws")
}
