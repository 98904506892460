import { Duration } from "duration-converter"

export const formatBalance = (rawBalance: string) => {
  const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(2)
  return balance
}

export const formatChainAsNum = (chainIdHex: string) => {
  const chainIdNum = parseInt(chainIdHex)
  return chainIdNum
}

export const formatFiatCurrency = (amount: number) => {
  return formatDigits(amount, 2)
}

export const formatTokenAmount = (amount: number) => {
  return formatDigits(amount, 4)
}

export const formatDigits = (amount: number, digits: number) => {
  return Math.round(amount * 10 ** digits) / 10 ** digits
}

export const formatLotsOfTokens = (amount: number) => {
  return  formatDigits(amount, 2).toLocaleString()
}

export const formatDuration = (seconds: number) => {
  return (seconds <= 0) ? "Ready" :
   Duration.fromSeconds(seconds).toString()
}

export const formatTimestamp = (seconds: number) => {
  // TODO: Improve, show month as word?
  return new Date(seconds * 1000).toLocaleDateString()
}
