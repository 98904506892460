import { createSlice } from "@reduxjs/toolkit"
import { App } from "../../constants"
import { bond, bondMarketDetails } from "../../shared/interfaces/bonds"
import { debug, trace } from "../../utils/trace"
import { token, icon } from "../../utils/token"


// TODO:
// * reset bondMarkets on chain change
// * usdPriceSlice
//  * multiple providers
//    * No. 1 = Cloudflare Proxy (handle with workers & distribute price updates via websocket)
//    * No. 2 = Coingecko 
//    * No. 3 = Whitebit (because our liquodity is there)
//    * No. 4 = Uniswap
//    * No. 5 = Assume Avg(DAI, USDT, USDC) = 1 USD and calculate from there
// * myBondHistory

const initialState = {
  usdPrice: {} as Record<string, number>,
  bondMarkets: [] as bondMarketDetails[],
  myBonds: [] as bond[]
}

const bondsSlice = createSlice({
  name: "bonds",
  initialState,
  reducers: {
    updateBondMarkets(state, action: { payload: { update: bondMarketDetails[], chainId: number } }) {
      const { update, chainId } = action.payload
      if (!update) {
        return
      }
      if (update.length === 0) {
        state.bondMarkets = []
        return
      }
      const bondMarkets = update.map((market) => {
        return enrichBondMarket(market, chainId, state.usdPrice)
      })
      state.bondMarkets = bondMarkets
    },
    updateBondMarketVolume(state, action: { payload: { marketId: number, soldAmount: number } }) {
      const { marketId, soldAmount } = action.payload
      const ix = state.bondMarkets.findIndex((market) => market.id === marketId)
      if (ix === -1) {
        trace("bondMarketNotInState", "bondsSlice", marketId)
        return
      }

      state.bondMarkets = state.bondMarkets.map((market) => {
        return market.id !== marketId ? market : {
          ...market,
          sold: market.sold + soldAmount
        }
      })
    },
    addBondMarket(state, action: { payload: { newMarket: bondMarketDetails, chainId: number } }) {
      const { newMarket, chainId } = action.payload
      if (!newMarket) {
        return
      }
      if (state.bondMarkets.find((market) => market.id === newMarket.id)) {
        return
      }
      const bondMarket = enrichBondMarket(newMarket, chainId, state.usdPrice)
      state.bondMarkets = [...state.bondMarkets, bondMarket].sort((a, b) => a.id - b.id)
    },
    removeBondMarket(state, action: { payload: { marketId: number } }) {
      const { marketId } = action.payload
      if (!marketId) {
        return
      }
      state.bondMarkets = state.bondMarkets.filter((bondMarket) => bondMarket.id !== marketId)
    },
    updateMyBonds(state, action: { payload: { update: bond[], chainId: number } }) {
      const { update, chainId } = action.payload
      if (!update) {
        return
      }
      if (update.length === 0) {
        state.myBonds = []
        return
      }
      const myBonds = update.map((bond) => {
        const quoteTokenTicker = token(chainId, bond.quoteTokenAddress)
        const quoteTokenIcon = icon(quoteTokenTicker)
        return {
          ...bond,
          quoteTokenTicker: quoteTokenTicker,
          quoteTokenIconSvg: quoteTokenIcon,
        }
      })
      state.myBonds = myBonds
    },
    addMyBond(state, action: { payload: { newBond: bond, chainId: number } }) {
      const { newBond, chainId } = action.payload
      if (!newBond) {
        return
      }
      if (state.myBonds.find((bond) => bond.id === newBond.id)) {
        return
      }
      const quoteTokenTicker = token(chainId, newBond.quoteTokenAddress)
      const quoteTokenIcon = icon(quoteTokenTicker)
      const myNewBond: bond = {
        ...newBond,
        quoteTokenTicker: quoteTokenTicker,
        quoteTokenIconSvg: quoteTokenIcon,
      }
      state.myBonds = [...state.myBonds, myNewBond].sort((a, b) => a.id - b.id)
    },
    removeClaimedBond(state, action: { payload: { claimedBond: bond } }) {
      const { claimedBond } = action.payload
      if (!claimedBond) {
        return
      }
      state.myBonds = state.myBonds.filter((bond) => bond.id !== claimedBond.id)
    },
    updateUsdPrices(state, action: { payload: { prices: Record<string, number> } }) {
        const { prices } = action.payload
        const newPrices = { ...state.usdPrice, ...prices }
        debug("updateUsdPrices", "bondsSlice", "state.usdPrice", state.usdPrice, "newPrices", newPrices)
        state.usdPrice = newPrices
    }
  },
})

export const { updateBondMarkets, updateBondMarketVolume, addBondMarket, removeBondMarket, 
  updateMyBonds, addMyBond, removeClaimedBond,
  updateUsdPrices } = bondsSlice.actions
export default bondsSlice.reducer

// HELPERS

function enrichBondMarket(market: bondMarketDetails, chainId: number, tokenUsdPrices: Record<string, number>) {
  const quoteTokenTicker = token(chainId, market.quoteTokenAddress)
  const quoteTokenIcon = icon(quoteTokenTicker)
  const tokenPriceUsd = tokenUsdPrices[App.TokenTicker] ?? 0
  const quoteTokenUsdPrice = tokenUsdPrices[quoteTokenTicker] ?? 0
  const priceUSD = market.priceQuoteToken * quoteTokenUsdPrice
  const discount = (priceUSD && tokenPriceUsd) ? priceUSD / tokenPriceUsd : undefined
  return {
    ...market,
    quoteTokenTicker: quoteTokenTicker,
    quoteTokenIconSvg: quoteTokenIcon,
    priceUSD: priceUSD,
    discount: discount,
  }
}
