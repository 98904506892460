import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Accordion from "react-bootstrap/Accordion"


export default function Faqs() {
  return (
    <section className="section-faqs">

      <Container>
        <Row>
          <Col className="col-12">
            <Accordion className="faq-accordion" id="faqsAccordion">
              <Accordion.Item eventKey="1">
                <Accordion.Header>What are bonds?</Accordion.Header>
                <Accordion.Body>
                  Bonds are an option by which DAO members can buy HDN off-market to avoid price impact and at the same time support the project. The funds raised from the bond sale goes directly to the DAO treasury and is used for development of Hydranet’s product, the Hydranet DEX.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How do bonds work?
                </Accordion.Header>
                <Accordion.Body>
                  Hydranet supporters can use the bonds app to buy bonds for a desired amount of funds. After a vesting period, buyers can claim their tokens by clicking “claim” in the bonds app. When the stakeholder claims the tokens, the acquired HDN will be moved to the buyer’s wallet.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How long is the vesting period?
                </Accordion.Header>
                <Accordion.Body>
                  The vesting period for bonds typically lasts between 5 to 7 days. This duration is implemented to prevent negative impacts on the token price resulting from arbitrage opportunities between the bond price and the token price.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What are the limits?
                </Accordion.Header>
                <Accordion.Body>
                  Each round of bonds sale has a specific limit on the number of available tokens. This means that a round of bond sales can be sold out. However, there are no restrictions on the maximum amount an individual stakeholder can purchase during each round of bond sales.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Who decides when bonds will be for sale and the amount of bonds available?
                </Accordion.Header>
                <Accordion.Body>
                  Every new 5 Million HDN used for bonds must be approved by the DAO via a public community vote first. If the vote is approved, the multi-sig team sets up the bonds and determines the number of available bonds for each round until the limit of 5 Million HDN has been reached.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  How are prices determined?
                </Accordion.Header>
                <Accordion.Body>
                  The multi-sig team establishes the initial price of the bonds during the setup process. Typically, the initial price is set ~ 10% lower than the current HDN token price. The only thing that can change the HDN price later is a price change of the quote currency (e.g. wETH or USDC, USDT, etc.).
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What coins are accepted?
                </Accordion.Header>
                <Accordion.Body>
                  We currently support the following tokens for bond purchases: wETH, DAI, USDC, USDT, and ARB.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  Are there any legal drawbacks?
                </Accordion.Header>
                <Accordion.Body>
                  Currently, US citizens and residents are not allowed to buy bonds. This restriction is in place to comply with US securities laws and regulations.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  Is there an upper limit on how many tokens can be sold through bonds?
                </Accordion.Header>
                <Accordion.Body>
                  Yes. Bonds will only be for sale until the maximum supply of HDN has reached 300 Million HDN.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  How do I know when bonds are available?
                </Accordion.Header>
                <Accordion.Body>
                  By accessing the bonds app you will see if bonds are available or not. You can also look in our Discord, in the “bonds-available” channel to see if bonds are available or not.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  )
}