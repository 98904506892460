import { createSlice } from "@reduxjs/toolkit"

interface account {
    address: string
    isConnected: boolean
}

const initialState = {
    chainId: 0,
    account: {
        address: "",
        isConnected: false,
    } as account,
    balances: {} as Record<string, number>,
    allowances: {} as Record<string, number>,
}

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        updateChainId(state, action: { payload: { chainId: number } }) {
            const { chainId } = action.payload
            state.chainId = chainId
        },
        updateAccount(state, action: { payload: { account: account } }) {
            const { account } = action.payload
            state.account.address = account.address
            state.account.isConnected = account.isConnected
        },
        updateAllowance(state, action: { payload: { token: string, allowance: number } }) {
            const { token, allowance } = action.payload
            if (state.allowances[token] === allowance) {
                return
            }
            state.allowances = updateRecordSubset(state.allowances, { [token]: allowance })
        },
        updateAllowances(state, action: { payload: { allowances: Record<string, number> } }) {
            const { allowances } = action.payload
            state.allowances = updateRecordSubset(state.allowances, allowances)
        },
        updateBalance(state, action: { payload: { token: string, balance: number } }) {
            const { token, balance } = action.payload
            if (state.balances[token] === balance) {
                return
            }
            state.balances = updateRecordSubset(state.balances, { [token]: balance })
        },
        updateBalances(state, action: { payload: { balances: Record<string, number> } }) {
            const { balances } = action.payload
            state.balances = updateRecordSubset(state.balances, balances)
        },
        processIncomingTransfer(state, action: { payload: { token: string, amount: number } }) {
            const { token, amount } = action.payload
            const balance = state.balances[token] ?? 0 + amount
            updateBalance({token, balance})
        },
    },
})

export const { updateChainId, updateAccount,
    updateAllowance, updateAllowances,
    updateBalance, updateBalances, processIncomingTransfer } = accountSlice.actions
export default accountSlice.reducer

// HELPER //

type Key = string | number | symbol

// Immutable, compatible with redux state
function updateRecordSubset<K extends Key, V>(original: Record<K, V>, updates: Record<K, V>): Record<K, V> {
    if (!updates) {
        return original
    }
    const merged = {} as Record<K, V>
    for (const key in original) {
        merged[key] = updates[key] ?? original[key]
    }
    return merged
}
