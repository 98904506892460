import { Chart as ChartJS, ArcElement, Tooltip, LinearScale, Title } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { App } from '../constants'
import { bondMarketDetails } from '../shared/interfaces/bonds'
import { formatLotsOfTokens } from '../utils/format'

ChartJS.register(ArcElement, Tooltip, LinearScale, Title)

export default function InfoActiveBonds({ bondMarkets }: { bondMarkets: bondMarketDetails[] }) {
  const tokens = bondMarkets.map(bm => `${bm.priceQuoteToken} ${bm.quoteTokenTicker!}`);
  const available = bondMarkets.map(bm => bm.volume - bm.sold)
  const totalAvailable = available.reduce((a, b) => a + b, 0)
  const sold = bondMarkets.map(bm => bm.sold).reduce((a, b) => a + b, 0)

  const activeBonds = {
    labels: tokens,

    datasets: [
      {
        label: '',
        data: available,

  
        backgroundColor: [
          '#CC2A91', // Argon
          '#be3f90', // Argon, not retarded anymore
          '#7d4ace', // Argon, not retarded anymore
          '#446cc1', // Argon, not retarded anymore
          '#39a3a1', // Argon, not retarded anymore
          '#558e52', // Argon, not retarded anymore
          '#949f4a', // Argon, not retarded anymore
          '#a73737', // Argon
        ],
        borderColor: [
          '#091126e0',
          '#091126e0',
        ],

        hoverOffset: 0,
        borderWidth: 1,
        cutout: 38,
        spacing: 0,
      
        // circumference: 360,
      },
  
    ],
    

  }

  return (
    <div className='card card-style-1'>
      <div className=' d-flex justify-content-between'>
        <div className='card-left'>
          <h3 className='card-title'>Active Bonds</h3>
          <div className='card-value'>{formatLotsOfTokens(totalAvailable)} {App.TokenTicker}</div>
          <div className='sub-text'>{formatLotsOfTokens(sold)} Sold Recently</div>
          {/* <div className='sub-text'>{sold} Sold Last 7 Days</div> */}
        </div>
        <div className='chart-right'>
          <Doughnut data={activeBonds}/>
        </div>
      </div>
    </div>
  )
}
