import { Chart as ChartJS, ArcElement, Tooltip, LinearScale } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { App } from '../constants'
import { formatLotsOfTokens } from '../utils/format'
ChartJS.register(ArcElement, Tooltip, LinearScale)

export const totalSupplyChart = {
  labels: ['Circulating', 'Locked'],
  datasets: [
    {
      label: 'Rate',
      data: [9.000, 1.000],

      backgroundColor: [
        '#1BA777',
        '#2C4057',
      ],
      borderColor: [
        '#1BA777',
        '#2C4057',
      ],
      hoverOffset: 0,
      borderWidth: 1,
      cutout: 40,
      spacing: 0,
      // circumference: 360,
    },

  ],
}

export default function InfoSupply({total, locked}: {total: number, locked: number}) {
  const circulating = total - locked
  const supplyChart = {
    labels: ['Circulating', 'Locked'],
    datasets: [
      {
        label: '',
        data: [circulating, locked],
  
        backgroundColor: [
          '#1BA777',
          '#2C4057',
        ],
        borderColor: [
          '#1BA777',
          '#2C4057',
        ],
        hoverOffset: 0,
        borderWidth: 1,
        cutout: 40,
        spacing: 0,
        // circumference: 360,
      },
  
    ],
  }

  return (
    <div className='card card-style-1'>
      <div className=' d-flex justify-content-between'>
        <div className='card-left'>
          <h3 className='card-title'>Circulating Supply</h3>
          <div className='card-value'>{formatLotsOfTokens(circulating)} {App.TokenTicker}</div>
          <div className='sub-text'>of total {formatLotsOfTokens(total)} {App.TokenTicker} Tokens</div>
          {/* <div className='sub-text'>+ ??? Last 12 Months</div> */}
        </div>
        <div className='chart-right'>
          <Doughnut data={supplyChart} />
        </div>
      </div>
    </div>
  )
}
