import { configureStore } from "@reduxjs/toolkit"
// import { stry } from "../shared/tools/stry"
import accountReducer from "./slices/accountSlice"
import tokenInfoReducer from "./slices/tokenInfoSlice"
import bondsReducer from "./slices/bondsSlice"

// const stryReduxEnhancer = stry.createReduxEnhancer({})

export const store = configureStore({
  reducer: {
    account: accountReducer,
    tokenInfo: tokenInfoReducer,
    bonds: bondsReducer,
  },
  devTools: process.env.REACT_APP_STAGE !== "prod",
  // enable caching, invalidation
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat()
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
