import { App } from "../../constants"
import { convertToToken } from "../../utils/convert"
import { tokenDigitsFromAddress } from "../../utils/token"
// INTERFACES //

export interface bondMarketDetails {
    id: number
    refToken: string,
    quoteTokenAddress: string,
    quoteTokenTicker?: string,
    quoteTokenIconSvg?: string,
    priceQuoteToken: number,
    priceUSD?: number,
    discount?: number,
    term: number, // seconds
    volume: number,
    sold: number,
}

export interface bond {
    id: number
    refToken: string,
    marketId: number
    owner: string
    term: number // seconds
    maturation: number // timestamp
    amount: number // payout in target token (HDN)
    priceQuoteToken: number
    quoteTokenAddress: string,
    quoteTokenTicker?: string,
    quoteTokenIconSvg?: string,
}

// TRANSFORMERS //

export function transformBondMarkets(activeMarkets: readonly bondageActiveMarket[]): bondMarketDetails[] {
    return activeMarkets.map((market) => {
        return transformBondMarket(market)
    }).sort((a, b) => a.id - b.id)
}

export function transformBondMarket(market: bondageActiveMarket): bondMarketDetails {
    return {
        refToken: App.TokenTicker,
        id: Number(market.id),
        quoteTokenAddress: market.quoteToken.toString(),
        priceQuoteToken: convertToToken(market.price, tokenDigitsFromAddress(market.quoteToken.toString())),
        term: Number(market.duration),
        volume: convertToToken(market.volume, App.TokenDecimals),
        sold: convertToToken(market.sold, App.TokenDecimals)
    }
}

export function transformMyBonds(myBonds: readonly bondageBond[]): bond[] {
    return myBonds.map(transformBond).sort((a, b) => a.id - b.id)
}

export function transformBond(bond: bondageBond): bond {
    return {
        refToken: App.TokenTicker,
        id: Number(bond.id),
        marketId: Number(bond.marketId),
        owner: bond.owner,
        term: Number(bond.term),
        maturation: Number(bond.maturation),
        amount: convertToToken(bond.amount, App.TokenDecimals),
        priceQuoteToken: convertToToken(bond.price, tokenDigitsFromAddress(bond.quoteToken.toString())),
        quoteTokenAddress: bond.quoteToken.toString(),
    }
}

// FROM GLUE //

interface bondageActiveMarket {
    id: bigint
    quoteToken: `0x${string}`
    price: bigint
    duration: bigint
    volume: bigint
    sold: bigint
}

interface bondageBond {
    id: bigint
    marketId: bigint
    owner: `0x${string}`
    term: bigint
    maturation: bigint
    amount: bigint
    price: bigint
    quoteToken: `0x${string}`
}
