import * as stray from "@sentry/react";
import { API_URL_ROOT } from "../api/hydranet/url"
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export const stry = stray
export const ErrorBoundary = stray.ErrorBoundary

export function stryInit() {
    const samplingRate = parseFloat(process.env.REACT_APP_SAMPLING_RATE ?? "1.0")
    stray.init({
        tunnel: `${API_URL_ROOT}/client/stry`,
        dsn: "https://f2df2c66560c4c8195a07f8f06b57240@stry.api.hydranet/4505289287860224",
        environment: process.env.REACT_APP_STAGE,
        autoSessionTracking: true,
        enableTracing: true,
        integrations: [
            new stray.BrowserTracing({
                tracePropagationTargets: [/.*/], // ["localhost", /^https:\/\/hydranet\.ai\/api/],
                routingInstrumentation: stray.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
                _experiments: {
                    enableInteractions: true,
                }

            }),
            new stray.Replay({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
                _experiments: {
                    captureExceptions: true,
                    traceInternals: true,
                }

            }),
        ],
        // Performance Monitoring
        tracesSampleRate: samplingRate,
        // Session Replay
        replaysSessionSampleRate: samplingRate,
        replaysOnErrorSampleRate: 1.0,
    });
}
