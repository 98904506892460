import { logEvent } from "../shared/api/hydranet/log"

const TRACE_LEVEL = process.env.REACT_APP_TRACE_LEVEL 
const TRACE_LEVEL_CONSOLE = process.env.REACT_APP_TRACE_LEVEL_CONSOLE

let INSTANCE: Tracer

export const log =  {
        error: (key: string, location: string, ...args: any[]) => {
            tracer().trace(1, key, location, ...args)
        },
        warn: (key: string, location: string, ...args: any[]) => {
            tracer().trace(2, key, location, ...args)
        },
        info: (key: string, location: string, ...args: any[]) => {
            tracer().trace(3, key, location, ...args)
        },
        debug: (key: string, location: string, ...args: any[]) => {
            tracer().trace(4, key, location, ...args)
        }
}

// Backwards compatibility
export const trace = log.error
export const debug = log.debug

function tracer() {
    if (INSTANCE === undefined) {
        INSTANCE = new Tracer(TRACE_LEVEL ?? 69, TRACE_LEVEL_CONSOLE ?? 69)
    }
    return INSTANCE
}

class Tracer {
    level: number
    consoleLevel: number
    constructor(level: number | string, consoleLevel: number | string) {
        this.level = (typeof level === "string") ? parseInt(level) : level
        this.consoleLevel = (typeof consoleLevel === "string") ? parseInt(consoleLevel) : consoleLevel
    }

    trace(level: number, key: string, location: string, ...args: any[]) {
        if (this.level >= level) {
            const event = {
                level: level,
                key: key,
                location: location,
                data: args
            }
            logEvent(event).catch(err => {
                this.console(1, "tracingError", "trace@Tracer", err, event)
            })
        }
        this.console(level, key, location, ...args)
    }

    console(level: number, key: string, location: string, ...args: any[]) {
        if (this.consoleLevel >= level) {
            console.log(`${key}@${location}`, ...args)
        }
    }
}

